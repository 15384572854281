import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
// import { useDocument } from "react-firebase-hooks/firestore";

import COLLECTIONS from "../decorators/firebase/collections";


export function usePlace(placeID) {

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [place, setPlace] = useState([]);

    // const [place, loading, error] = useDocument(
    //     firebase
    //         .firestore()
    //         .collection(COLLECTIONS.PLACES)
    //         .doc(placeID)
    // );

    useEffect(
        () => {
            firebase
                .firestore()
                .collection(COLLECTIONS.PLACES)
                .doc(placeID)
                .get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such document!');
                        setLoading(false);
                    } else {

                        console.log('Document data:', doc.data());
                        const {location, ...data} = doc.data();

                        setLoading(false);
                        setPlace({
                            ...data,
                            location: [location.latitude, location.longitude]
                        });
                    }
                })
                .catch(err => {
                    console.log('Error getting document', err);
                    setLoading(false);
                });
        },
        [placeID]
    );

    return {
        error,
        loading,
        place,
    }
}
