import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import {withFirebase} from '../../decorators/firebase';
import PlaceContainer from './components/container';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Snackbar from '../../components/shared/Snackbar';
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import PlaceForm from "./PlaceForm";

// https://github.com/suevalov/next-blog-firestore/blob/master/stores/postsStore.js
const styles = theme => {
	return {
		mainContent  : {
			flex           : 1,
			maxWidth       : 936,
			margin         : 'auto',
			overflow       : 'hidden',
			padding        : '48px 36px 0',
			backgroundColor: 'transparent',
		},
		card__header : {
			backgroundColor: theme.palette.primary.main,
			padding        : '16px 0',
			// position       : 'fixed',
		},
		secondary_bar: {
			zIndex: 0,
		},

	};
};

@withStyles(styles)
@withFirebase
class PlacesEdit extends React.Component {
	constructor(props) {
		super(props);
		const {match} = props;
		this.placeId = match.params.placeId;

		this.state = {
			open   : false,
			data   : {},
			loading: true,
		};
	}

	componentDidMount() {
		const {firebase} = this.props;
		this.placeRef = firebase.place(this.placeId);
		console.log('id', this.placeRef.id);
		this.placeRef.get()
			.then(doc => {
				if (!doc.exists) {
					console.log('No such document!');
					this.setState({loading: false});
				} else {

					console.log('Document data:', doc.data());
					const {location, ...data} = doc.data();
					this.setState({
						loading: false,
						data   : {
							...data,
							location: [location.latitude, location.longitude]
						}
					});
				}
			})
			.catch(err => {
				console.log('Error getting document', err);
				this.setState({loading: false});
			});

	}

	onSubmit = (values) => {
		const {image, location, ...data} = values;
		const {firebase} = this.props;

		const id = this.placeRef.id;

		if(image) {
			const storageRef = firebase.bucket().ref();
			const uploadTask = storageRef.child(`places/${id}/${image.name}`).put(image);
			uploadTask.on(firebase.bucket.TaskEvent.STATE_CHANGED,
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log('Upload is ' + progress + '% done');
					switch (snapshot.state) {
						case firebase.bucket.TaskState.PAUSED: // or 'paused'
							console.log('Upload is paused');
							break;
						case firebase.bucket.TaskState.RUNNING: // or 'running'
							console.log('Upload is running');
							break;
						default:
							break;
					}
				},
				(error) => {
					console.error(error);
				},
				() => {
					uploadTask.snapshot.ref.getDownloadURL()
						.then((downloadURL) => {
							this.placeRef.set({
								id,
								...data,
								updated_at: new Date(),
								images    : [downloadURL],
								location  : new firebase.app.firestore.GeoPoint(...location)
							}).then(res => {
								this.setState({open: true});
							});
						});
				},
			);
		} else {
			this.placeRef.set({
				id,
				...data,
				updated_at: new Date(),
				location  : new firebase.app.firestore.GeoPoint(...location)
			}).then(res => {
				this.setState({open: true});
			});
		}
	};

	handleClose = () => {
		this.setState({
			open: false,
		});
	};

	render() {
		const {classes} = this.props;
		const {loading, open, data} = this.state;

		if (loading) {
			return null;
		}

		return (
			<PlaceContainer>
				<main className={classes.mainContent}>
					{loading && <div>Loading ...</div>}

					<Card className={classes.card}>
						<CardHeader className={classes.card__header} disableTypography
									title={<AppBar
										component="div"
										className={classes.secondary_bar}
										color="primary"
										position="static"
										elevation={0}>
										<Toolbar>
											<Grid container spacing={8} alignItems="center">
												<Grid item xs>
													<Typography color="inherit" variant="h5">
														New Place
													</Typography>
												</Grid>
												<Grid item/>
											</Grid>
										</Toolbar>
									</AppBar>}/>

						<CardContent>
							<Typography gutterBottom variant="h5" component="h2">
								Lizard
							</Typography>
							<Typography variant="body2" color="textSecondary" component="p">
								Lizards are a widespread group of squamate reptiles, with over 6,000 species,
								ranging
								across all continents except Antarctica
							</Typography>
							<PlaceForm onSubmit={this.onSubmit} initialValues={data}/>
						</CardContent>
					</Card>

				</main>
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={this.handleClose}
					message="Successfull save!!!"
				/>
			</PlaceContainer>
		);
	}
}

export default PlacesEdit;
