import React from 'react';
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
	paper         : {
		maxWidth: 936,
		margin  : 'auto',
		overflow: 'hidden',
	},
	searchBar     : {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	contentWrapper: {
		// margin: '40px 16px',
	},
});

const Container = ({classes, header, children}) => (
	<Paper className={classes.paper}>
		{header && (
			<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
				{header}
			</AppBar>
		)}
		<div className={classes.contentWrapper}>
			{children}
		</div>
	</Paper>
);

Container.propTypes = {
	header  : PropTypes.node,
	children: PropTypes.node.isRequired,
	classes : PropTypes.object.isRequired,
};

export default withStyles(styles)(Container);
