export const containsSome = (superset, subset = []) => {
	if (0 === subset.length) {
		return false;
	}
	return subset.some((value) => superset.includes(value));
};

export default {
	containsSome
}
