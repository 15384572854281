import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import withPublicTheme from "../../decorators/theme/withPublicTheme";
import {AppAppBar} from "../../common";

const styles = {
	root      : {
		display  : 'flex',
		minHeight: '100vh',
	},
	appContent: {
		flex         : 1,
		display      : 'flex',
		flexDirection: 'column',
	}
};

@withPublicTheme
@withStyles(styles)
class PublicLayout extends React.Component {
	static propTypes = {
		classes: PropTypes.object,
	};

	render() {
		const {classes, children} = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.appContent}>
					<AppAppBar/>
					{children}
				</div>
			</div>
		);
	}
}

export default PublicLayout;
