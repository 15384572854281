import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";

import COLLECTIONS from "../decorators/firebase/collections";
import {getLatLngCenter} from "../utils/geo";

export function usePlaces() {

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState([]);
    const [center, setCenter] = useState([]);

    useEffect(
        () => {
            const unsubscribe = firebase
                .firestore()
                .collection(COLLECTIONS.PLACES)
                .onSnapshot(
                    snapshot => {
                        const locations = [];
                        setPlaces(snapshot.docs.map(doc => {
                            const {location, ...data} = doc.data();
                            const latLng =  [location.latitude, location.longitude];
                            locations.push(latLng);
                            return {
                                ...data,
                                location: latLng,
                                uid: doc.id,
                            }
                        }));
                        setCenter(getLatLngCenter(locations, true));
                        setLoading(false);
                    },
                    err => {
                        setError(err)
                    }
                );

            return () => unsubscribe()
        },
        []
    );

    return {
        error,
        loading,
        places,
        center
    }
}
