import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
	container             : {
		display       : 'flex',
		alignItems    : 'center',
		justifyContent: 'center',
		width         : '100%',
	},
	ellipsis              : {
		display : 'inline-block',
		position: 'relative',
		width   : '64px',
		height  : '64px',
		'& div' : {
			position               : 'absolute',
			top                    : '27px',
			width                  : '11px',
			height                 : '11px',
			borderRadius           : '50%',
			background             : theme.palette.primary.main,
			animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
			'&:nth-child(1)'       : {
				left     : '6px',
				animation: 'ellipsis1 0.6s infinite'
			},
			'&:nth-child(2)'       : {
				left     : '6px',
				animation: 'ellipsis2 0.6s infinite',
			},
			'&:nth-child(3)'       : {
				left     : '26px',
				animation: 'ellipsis2 0.6s infinite',
			},
			'&:nth-child(4)'       : {
				left     : '45px',
				animation: 'ellipsis3 0.6s infinite',
			}
		}
	},
	'@keyframes ellipsis1': {
		'0%'  : {
			transform: 'scale(0)',
		},
		'100%': {
			transform: 'scale(1)'
		}
	},
	'@keyframes ellipsis3': {
		'0%'  : {
			transform: 'scale(1)',
		},
		'100%': {
			transform: 'scale(0)'
		}
	},
	'@keyframes ellipsis2': {
		'0%'  : {
			transform: 'translate(0, 0)',
		},
		'100%': {
			transform: 'translate(19px, 0)'
		}
	}
});

const DotsLoaderComponent = ({classes}) => (
	<div className={classes.container}>
		<div className={classes.ellipsis}>
			<div/>
			<div/>
			<div/>
			<div/>
		</div>
	</div>
);

export const DotsLoader = withStyles(styles)(DotsLoaderComponent);
export default DotsLoader;
