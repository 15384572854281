import React from 'react';
import {Route, Switch} from "react-router-dom";

import ROUTES from "../../constants/routes";
import Layout from "./Layout";

import Home from "../../public/containers/home";
import Places from "../../public/containers/places";
import Place from "../../public/containers/place";

export default (props) => (
	<Layout>
		<Switch>
			<Route exact path={ROUTES.LANDING} component={Home}/>
			<Route exact path={ROUTES.PUBLIC_PLACES} component={Places}/>
			<Route path={ROUTES.PUBLIC_PLACES_VIEW} component={Place}/>
		</Switch>
	</Layout>
);
