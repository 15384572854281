import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import withAdminTheme from "../../decorators/theme/withAdminTheme";
import withAuthorization from "../../decorators/auth/withAuthorization";
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import {containsSome} from "../../services/array.service";
import ROLES from '../../constants/roles';

const drawerWidth = 256;

const styles = theme => ({
	root      : {
		display  : 'flex',
		minHeight: '100vh',
	},
	drawer    : {
		[theme.breakpoints.up('sm')]: {
			width     : drawerWidth,
			flexShrink: 0,
		},
	},
	appContent: {
		flex         : 1,
		display      : 'flex',
		flexDirection: 'column',
	}
});

@withAdminTheme
@withAuthorization(authUser => !!authUser ? containsSome([ROLES.ADMIN, ROLES.SU], authUser._roles) : false)
@withStyles(styles)
class PrivateLayout extends Component {
	static propTypes = {
		classes: PropTypes.object,
	};

	state = {
		mobileOpen: false,
	};

	handleDrawerToggle = () => {
		this.setState(state => ({mobileOpen: !state.mobileOpen}));
	};

	render() {
		const {classes} = this.props;

		return (
			<Fragment>
				<div className={classes.root}>
					<nav className={classes.drawer}>
						<Hidden smUp implementation="js">
							<Navigator
								PaperProps={{style: {width: drawerWidth}}}
								variant="temporary"
								open={this.state.mobileOpen}
								onClose={this.handleDrawerToggle}/>
						</Hidden>
						<Hidden xsDown implementation="css">
							<Navigator PaperProps={{style: {width: drawerWidth}}}/>
						</Hidden>
					</nav>
					<div className={classes.appContent}>
						<Header onDrawerToggle={this.handleDrawerToggle}/>
						<Content/>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default PrivateLayout;

