import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Typography} from '../../../common';

const styles = theme => ({
	paper           : {},
	item            : {},
	item__container : {
		borderRadius: '4px',
	},
	title           : {
		marginBottom: theme.spacing(14),
	},
	number          : {
		fontSize  : 24,
		fontFamily: theme.typography.fontFamily,
		color     : theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	image__container: {
		padding: '2px',
	},
	image           : {
		borderRadius: '4px',
		width       : 320,
		height      : 200,
		// marginTop   : theme.spacing(4),
		// marginBottom: theme.spacing(4),
	},
	button          : {
		marginTop: theme.spacing(8),
	},
	content         : {
		padding: '12px 16px'
	},
});

/*
image     : null,
	name      : null,
	address   : null,
	status    : 'draft',
	history   : null,
	know      : null,
	transport : {
		own   : null,
		public: null
	},
	activities: [],
	location  : []
 */
class PlaceItemComponent extends React.Component {
	renderActivities(activities, length = 4) {
		if (activities.length > length) {
			activities = activities.slice(0, length - 1);
		}
		return (
			<Typography variant="body2" gutterBottom color="textSecondary">
				{activities.join(' • ')}
			</Typography>
		)
	}

	renderImages() {
		const {classes, place} = this.props;
		return (
			<Grid item className={classes.image__container}>
				{place.images && (
					<img
						src={place.images[0]}
						alt={place.name}
						className={classes.image}
					/>
				)}
			</Grid>
		)
	}

	render() : React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
		const {classes, place} = this.props;
		console.log('place', place);
		return (
			<Grid item container xs={12} md={6} className={classes.item__container}>
				<div className={classes.item}>
					<Paper className={classes.paper}>
						<Grid container>
							{this.renderImages()}


							<Grid item xs={12} sm container className={classes.content}>
								<Grid item xs container direction="column">
									<Grid item xs>
										<Typography gutterBottom variant="subtitle1">
											{place.name}
										</Typography>
										<Typography variant="body2" gutterBottom>
											{place.duration}
										</Typography>
										<Typography variant="body2" color="textSecondary">
											{place.address}
										</Typography>
										{this.renderActivities(place.activities)}
									</Grid>
									<Grid item>
										<Typography variant="body2" style={{cursor: 'pointer'}}>
											Remove
										</Typography>
									</Grid>
								</Grid>
								<Grid item>
									<Typography variant="subtitle1">$19.00</Typography>
								</Grid>
							</Grid>

						</Grid>
					</Paper>
				</div>
			</Grid>);
	}
}

export const PlaceItem = withStyles(styles)(PlaceItemComponent);
export default PlaceItem;

/*
<Grid item xs={12} sm container>
						<Typography variant="h5" align="center">
							{place.name}
						</Typography>
					</Grid>
 */
