import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

import {LayoutBody, Typography} from '../../../common';

const styles = theme => ({
	root  : {
		display      : 'flex',
		flexDirection: 'column',
		alignItems   : 'center',
		marginTop    : theme.spacing(9),
		marginBottom : theme.spacing(9),
	},
	button: {
		border      : '4px solid currentColor',
		borderRadius: 0,
		height      : 'auto',
		padding     : `${theme.spacing(2)}px ${theme.spacing(5)}px`,
	},
	link  : {
		marginTop   : theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	buoy  : {
		width: 60,
	},
});

function QuestionsComponent(props) {
	const {classes} = props;

	return (
		<LayoutBody className={classes.root} component="section">
			<Button className={classes.button} href='mailto:takpanama@gmail.com?subject=Preguntas'>
				<Typography variant="h4" component="span">
					¿Tienes alguna pregunta?
				</Typography>
			</Button>
			<Typography variant="subtitle1" className={classes.link}>
				Estamos aquí para ayudate. ¡Estaremos en contacto!
			</Typography>
		</LayoutBody>
	);
}

QuestionsComponent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export const Questions = withStyles(styles)(QuestionsComponent);
export default Questions;
