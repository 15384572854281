import React from 'react';
import PropTypes from "prop-types";
import {compose} from 'recompose';
import Link from "@material-ui/core/Link/Link";

import {withFirebase} from '../../decorators/firebase';
import {withRouter} from "react-router-dom";
import withAuthentication from "../../decorators/auth/withAuthentication";

const SignOutButton = ({className, authUser, firebase, children = 'Sign Out', extras}) => {
	if (!authUser) {
		return null;
	}
	return (
		<React.Fragment>
			<Link
				{...extras}
				className={className}
				onClick={() => firebase.doSignOut()}>
				{children}
			</Link>
		</React.Fragment>
	);
};

SignOutButton.propTypes = {
	className: PropTypes.string,
};

export default compose(
	withFirebase,
	withRouter,
	withAuthentication)(SignOutButton);
