import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import {withFirebase} from "../../../decorators/firebase";
import {Button, LayoutBody, Snackbar, TextField, Typography} from '../../../common';

const styles = theme => ({
	root         : {
		marginTop   : theme.spacing(10),
		marginBottom: 0,
		display     : 'flex',
	},
	cardWrapper  : {
		zIndex: 1,
	},
	card         : {
		display        : 'flex',
		justifyContent : 'center',
		backgroundColor: theme.palette.warning.main,
		padding        : `${theme.spacing(8)}px ${theme.spacing(3)}px`,
	},
	cardContent  : {
		maxWidth: 400,
	},
	textField    : {
		width       : '100%',
		marginTop   : theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	textArea     : {
		width       : '100%',
		marginBottom: theme.spacing(2),
	},
	button       : {
		width: '100%',
	},
	imagesWrapper: {
		position: 'relative',
	},
	imageDots    : {
		position  : 'absolute',
		top       : -67,
		left      : -67,
		right     : 0,
		bottom    : 0,
		width     : '100%',
		background: 'url(/static/onepirate/productCTAImageDots.png)',
	},
	image        : {
		position: 'absolute',
		top     : -28,
		left    : -28,
		right   : 0,
		bottom  : 0,
		width   : '100%',
		maxWidth: 700,
	},
});

class SharePlaceComponent extends React.Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
	};

	state = {
		open         : false,
		valid        : false,
		name         : '',
		nameLength   : null,
		address      : '',
		addressLength: null,
	};

	handleSubmit = event => {
		event.preventDefault();

		const {name, nameLength, address, addressLength} = this.state;
		if (!(!!nameLength && !!addressLength)) {
			this.setState({
				open: true,
			});
			return;
		}
		const newDraftRef = this.props.firebase.suggestedPlaces().doc();
		const id = newDraftRef.id;

		const SuggestedPlacesStatus = {
			Draft   : "draft",
			Reviewed: "reviewed",
			Reject  : "reject",
			Approved: "approved",
		};

		// self.posts.set(id, newPost);

		newDraftRef.set({
			id,
			name,
			address,
			created_at: new Date(),
			status    : SuggestedPlacesStatus.Draft,
		}).then(res => {
			this.setState({
				open         : true,
				name         : '',
				nameLength   : null,
				address      : '',
				addressLength: null,
			});
		});
	};

	handleChange = name => event => {
		this.setState({
			[name]           : event.target.value,
			[`${name}Length`]: event.target.value.length,
		}, () => {
			const {nameLength, addressLength} = this.state;
			this.setState({
				valid: !!nameLength && !!addressLength
			})
		});
	};

	handleClose = () => {
		this.setState({
			open: false,
		});
	};

	render() {
		const {classes} = this.props;
		const {open, valid, name, address} = this.state;

		return (
			<LayoutBody className={classes.root} component="section" width="large">
				<Grid container spacing={0}>
					<Grid item xs={12} md={6} className={classes.cardWrapper}>
						<div className={classes.card}>
							<form onSubmit={this.handleSubmit} className={classes.cardContent}>
								{
									/*
									<Typography variant="h3" component="h3" gutterBottom>
									Sugiere un Lugar
								</Typography>
									 */
								}
								<Typography variant="h5">
									Sabemos que Panamá tiene muchos más lugares increíbles y que tú conoces algunos que
									no tenemos aquí. Compartenos esos
									lugares.
								</Typography>
								<TextField noBorder className={classes.textField}
										   value={name}
										   onChange={this.handleChange('name')}
										   placeholder="Nombre del Lugar"/>
								<TextField noBorder className={classes.textArea}
										   value={address}
										   onChange={this.handleChange('address')}
										   placeholder="Como llegar" multiline
										   rows={3}/>
								{valid && (<Button
									type="submit"
									color="primary"
									variant="contained"
									className={classes.button}>
									Sugerir
								</Button>)
								}
							</form>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className={classes.imagesWrapper}>
						<Hidden smDown>
							<div className={classes.imageDots}/>
							<img
								src="/images/panama/cerroancon.jpg"
								alt="call to action"
								className={classes.image}
							/>
						</Hidden>
					</Grid>
				</Grid>
				<Snackbar
					open={open}
					autoHideDuration={3 * 1000}
					onClose={this.handleClose}
					message="Hemos recibido tu colaboración, Gracias."
				/>
			</LayoutBody>
		);
	}
}

export const SharePlace = compose(
	withStyles(styles),
	withFirebase
)(SharePlaceComponent);

export default SharePlace;
