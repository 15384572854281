import React from 'react';
import {Switch} from "react-router-dom";

import ROUTES from "../../constants/routes";
import AdminRoute from "../../components/shared/AdminRoute";

import List from "./list";
import Add from "./add";
import Edit from "./edit";

export default () => (
	<Switch>
		<AdminRoute exact path={ROUTES.PLACES_LIST} component={List}/>
		<AdminRoute exact path={ROUTES.PLACES_EDIT} component={Edit}/>
		<AdminRoute exact path={ROUTES.PLACES_ADD} component={Add}/>
		<AdminRoute path={ROUTES.PLACES} component={List}/>
	</Switch>
);
