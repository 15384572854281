import React from 'react';
import {useField} from 'informed';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';


const useStyles = makeStyles((theme) => createStyles({
	input: {
		display: 'none'
	}
}));

export default (props) => {
    const classes = useStyles();
    const {fieldState, fieldApi, render, ref, userProps, informed} = useField(props);
    const {onChange, onBlur, name, ...informedProps} = informed;
    const {setValue, setTouched} = fieldApi;
    const {
        helperText,
        id = String(Math.floor(Math.random() * 1e8)),
        ...rest
    } = userProps;


    const _onChange= e => {
        setValue(e.target.files[0]);
        onChange && onChange(e);
    };
    const _onBlur=e => {
        setTouched(true);
        onBlur && onBlur(e);
    };

    return render(
        <FormControl error={!!fieldState.error} className={classes.form_control}>
            <input
                {...rest}
                ref={ref}
                name={name}
                id={id}
                className={classes.input}
                accept="image/*"
                type="file"
                onChange={_onChange}
                onBlur={_onBlur}
            />

            <label htmlFor={id}>
                <IconButton color="primary" component="span">
                    <PhotoCamera/>
                </IconButton>
            </label>

            <FormHelperText>{fieldState.error || helperText}</FormHelperText>
        </FormControl>
    );
};
