import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import {LazyLoadImage} from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme) => createStyles({
}));

export const PlaceCarousel = ({place}) => {
    const classes = useStyles();
    const children = place.images.map((image, index) => (
        <LazyLoadImage
            key={`place--image-${index}`}
            src={image}
            alt={`${place.name} ${index}`}
            loading="lazy"
            placeholderSrc={'https://via.placeholder.com/400'}
            className={classes.image}
            width="300"
            height="260"
        />
    ));

    if (place.images.length === 1) {
        return (<div>
            {children}
        </div>);
    }
    return (
        <Carousel showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  autoPlay
                  swipeable>
            {children}
        </Carousel>
    );
};

