import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/#react-application-setup
// https://benmcmahen.com/using-firebase-with-react-hooks/
// https://github.com/bmcmahen/julienne/tree/master/functions
// react-firebase-hooks/auth
// https://github.com/molefrog/wouter
// react-filepond filepond
// firestore-pagination-hook
