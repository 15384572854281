import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import AuthUserContext from './context';
import {withFirebase} from '../firebase';
import * as ROUTES from '../../constants/routes';
import {containsSome} from "../../services/array.service";

const withRole = role => Component => {
	class WithRole extends React.Component {

		condition = (authUser) => !!authUser ? containsSome(role, authUser._roles) : false;

		componentDidMount() {
			this.listener = this.props.firebase.onAuthUserListener(authUser => {
					if (!this.condition(authUser)) {
						// this.props.history.push(ROUTES.PUBLIC);
					}
				},
				() => {/*this.props.history.push(ROUTES.SIGN_IN),*/});
		}

		componentWillUnmount() {
			this.listener();
		}

		render() {
			return (
				<AuthUserContext.Consumer>
					{authUser => this.condition(authUser) ? <Component {...this.props} /> : null}
				</AuthUserContext.Consumer>
			);
		}
	}

	return compose(
		withRouter,
		withFirebase,
	)(WithRole);
};

export default withRole;
