import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Typed from 'react-typed';

import MasterSlider, {Caption, Slide} from '../../../libs/MasterSlider';
import {Carousel} from "react-responsive-carousel";
import '../../../styles/libs/masterslider/masterslider.css';


const styles = theme => ({
    button: {
        minWidth: 200,
    },
    more: {
        marginTop: theme.spacing(2),
    },
    slide: {},
    slide__image: {},
    slide__layer: {
        position: 'absolute',
        pointerEvents: 'auto',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        zIndex: 109,
        top: 0,
    },
});

const SliderComponent = ({classes}) => (
    <section>
        <Carousel showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  interval={5000}
                  infiniteLoop
                  autoPlay={false}
                  swipeable
                  useKeyboardArrows
                  emulateTouch>
            <div className={classes.slide}>
                <img
                    src="/images/sliders/slide_old_panama.jpg"
                    alt="slide one"
                    className={classes.slide__image}
                />
                <div className={classes.slide__layer}>
                    <Caption
                        duration={487}
                        offSetX={0}
                        offSetY={85}
                        delay={1162}
                        origin={'mc'}>
                        TAK APP
                    </Caption>
                    <Caption
                        container={'msp-cn-1-4'}
                        duration={475}
                        offSetX={0}
                        offSetY={-6}
                        delay={712}
                        origin={'mc'}>
                        <Typed
                            strings={['CONECTA', 'DESCUBRE', 'EXPLORA']}
                            typeSpeed={40}
                            backSpeed={50}
                            loop/>
                    </Caption>
                </div>
            </div>
            <div>
                <img
                    src="/images/sliders/slide_causeway.jpg"
                    alt="slide one"
                    className={classes.slide__image}
                />
                <div className={classes.slide__layer}></div>
            </div>
            <div>
                <img
                    src="/images/sliders/slide_cinta_costera.jpg"
                    alt="slide one"
                    className={classes.slide__image}
                />
                <div className={classes.slide__layer}></div>
            </div>
        </Carousel>
    </section>
);

SliderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export const Slider = compose(
    withStyles(styles),
    withRouter)(SliderComponent);

export default Slider;

/**
 <MasterSlider id="5l1d3rs">
 <Slide image={'/images/sliders/slide_old_panama.jpg'} delay={3}>
 <Caption
 duration={487}
 offSetX={0}
 offSetY={85}
 delay={1162}
 origin={'mc'}>
 TAK APP
 </Caption>
 <Caption
 container={'msp-cn-1-4'}
 duration={475}
 offSetX={0}
 offSetY={-6}
 delay={712}
 origin={'mc'}>
 <Typed
 strings={['CONECTA', 'DESCUBRE', 'EXPLORA']}
 typeSpeed={40}
 backSpeed={50}
 loop/>
 </Caption>
 <Caption
 container={'msp-cn-1-3'}
 duration={475}
 offSetX={0}
 offSetY={185}
 delay={1612}
 origin={'mc'}>
 {

						<Button
						color="secondary"
						variant="contained"
						size="large"
						className={classes.button}
						component={linkProps => (
							<Link {...linkProps} variant="button"
								  component={(props) => (<RouterLink to={ROUTES.SIGN_IN} {...props}/>)}/>
						)}>
						Register
					</Button>
}
 </Caption>
 </Slide>
 <Slide image={'/images/sliders/slide_causeway.jpg'} delay={3}>
 <Caption
 duration={487}
 offSetX={710}
 offSetY={415}
 delay={712}
 origin={'tl'}>
 Conoce
 </Caption>
 <Caption
 container={'msp-cn-1-4'}
 duration={475}
 offSetX={700}
 offSetY={510}
 delay={1162}
 origin={'tl'}>
 Panamá
 </Caption>
 </Slide>
 <Slide image={'/images/sliders/slide_cinta_costera.jpg'} delay={3}>
 <Caption
 duration={487}
 offSetX={350}
 offSetY={415}
 delay={712}
 origin={'tl'}>
 Aventuremonos
 </Caption>
 <Caption
 container={'msp-cn-1-4'}
 duration={475}
 offSetX={520}
 offSetY={510}
 delay={1162}
 origin={'tl'}>
 Juntos
 </Caption>
 </Slide>
 </MasterSlider>
 */