import React from 'react';
import {compose} from 'recompose';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import * as ROUTES from '../constants/routes';

import Toolbar, {styles as toolbarStyles} from './toolbar.component';
import {AppHeaderAuth} from "./appHeaderAuth.component";
import {AppBar} from './appBar.component';

const styles = theme => ({
	appTitle      : {
		textDecoration: 'none',
		color         : 'inherit',
	},
	title         : {
		fontSize: 24,
	},
	placeholder   : toolbarStyles(theme).root,
	toolbar       : {
		justifyContent: 'space-between',
	},
	left          : {
		flex: 1,
	},
	leftLinkActive: {
		color: theme.palette.common.white,
	}
});

const AppAppBarComponent = ({classes}) => (
	<div>
		<AppBar position="fixed">
			<Toolbar className={classes.toolbar}>
				<div className={classes.left}>
					<Link
						variant="h6"
						underline="none"
						color="inherit"
						className={classes.title}
						component={(props) => (<RouterLink to={ROUTES.LANDING} {...props}/>)}>
						{'takapp'}
					</Link>
				</div>
				<AppHeaderAuth/>
			</Toolbar>
		</AppBar>
		<div className={classes.placeholder}/>
	</div>
);

AppAppBarComponent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export const AppAppBar = compose(
	withStyles(styles),
	withRouter
)(AppAppBarComponent);

export default AppAppBar;
