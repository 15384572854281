import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroller';

import {DotsLoader} from '../../components/common';
import {PlaceItem2} from '../../components/places';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        padding: `${theme.spacing() * 5}px`,
        overflow: 'hidden',
    }
}));


function PlacesComponent({places, loading}) {
    const classes = useStyles();
    return (
        <InfiniteScroll
            element={Grid}
            className={classes.root}
            pageStart={0}
            container
            spacing={2}
            loadMore={() => null}
            hasMore={false}
            loader={<DotsLoader key={0}/>}
            useWindow={true}>
            {places.map((place, index) => (<PlaceItem2 key={index} place={place}/>))}
        </InfiniteScroll>
    );
}

export default PlacesComponent;
