import React from 'react';
// import AppAppBar from "../../views/AppAppBar";
// import AppFooter from "../../views/AppFooter";
// import Timer from "../../components/common/timer.component";
import {Questions, SharePlace, Slider, WhatIsTak} from '../../components/home';

const HomeComponent = () => (
	<React.Fragment>
		<Slider/>
		<WhatIsTak/>
		<SharePlace/>
		<Questions/>
		{/* AppFooter */}
	</React.Fragment>
);

export const Home = HomeComponent;

export default Home;

