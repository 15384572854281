import React from 'react';
import {Switch} from "react-router-dom";

import ROUTES from "../../constants/routes";
import AdminRoute from "../../components/shared/AdminRoute";
import List from "./list";
import Add from "./add";

export default () => (
	<Switch>
		<AdminRoute exact path={ROUTES.SUGGESTED_PLACES_LIST} component={List}/>
		<AdminRoute exact path={ROUTES.SUGGESTED_PLACES_EDIT} component={Add}/>
		<AdminRoute path={ROUTES.SUGGESTED_PLACES} component={List}/>
	</Switch>
);
