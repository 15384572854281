import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import LayoutBody from '../components/public/LayoutBody';
import Paper from '../components/public/Paper';

const styles = theme => ({
	root : {
		display         : 'flex',
		backgroundImage : 'url(/images/appCurvyLines.png)',
		backgroundRepeat: 'no-repeat',
	},
	paper: {
		padding                     : `${theme.spacing(4)}px ${theme.spacing(3)}px`,
		[theme.breakpoints.up('md')]: {
			padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
		},
	},
});

const AppForm = ({children, classes}) => (
	<div className={classes.root}>
		<LayoutBody margin marginBottom width="small">
			<Paper className={classes.paper}>{children}</Paper>
		</LayoutBody>
	</div>
);

AppForm.propTypes = {
	children: PropTypes.node.isRequired,
	classes : PropTypes.object.isRequired,
};

export default withStyles(styles)(AppForm);
