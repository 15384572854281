function rad2degr(rad) {
    return rad * 180 / Math.PI;
}

function degr2rad(degr) {
    return degr * Math.PI / 180;
}

/**
 * @param points array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @param LatLng boolean
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 */
export function getLatLngCenter(points, LatLng = false) {
    const LATIDX = 0;
    const LNGIDX = 1;
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    const length = points.length;

    points.forEach((point) => {
        const lat = degr2rad(point[LATIDX]);
        const lng = degr2rad(point[LNGIDX]);
        // sum of cartesian coordinates
        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);
    });

    const avgX = sumX / length;
    const avgY = sumY / length;
    const avgZ = sumZ / length;

    // convert average x, y, z coordinate to latitude and longtitude
    const lng = rad2degr(Math.atan2(avgY, avgX));
    const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    const lat = rad2degr(Math.atan2(avgZ, hyp));

    return LatLng ? {lat, lng} : [lat, lng];
}

export function point2LatLng(point) {
    const [lat, lng] = point;
    return {lat, lng};
}
