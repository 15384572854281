import React from 'react';

import {usePlaces} from "../../../hooks/usePlaces";
import {Loading} from "../../../atoms/Loading";

import PlacesComponent from './places.component';

function PlacesContainer() {
    const {error, loading, places, center} = usePlaces();

    if(loading){
        return  <Loading />
    }

    return (
        <PlacesComponent places={places}
                         center={center}
                         loading={loading}/>
    );
}

export const Places = PlacesContainer;
export default Places;
