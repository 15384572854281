import React from 'react';
import {compose} from 'recompose';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import * as ROUTES from '../constants/routes';
import withAuthentication from "../decorators/auth/withAuthentication";
import SignOutButton from "../components/shared/SignOutButton";

const styles = theme => ({
	right        : {
		flex          : 1,
		display       : 'flex',
		justifyContent: 'flex-end',
	},
	rightLink    : {
		fontSize  : 16,
		color     : theme.palette.common.white,
		marginLeft: theme.spacing(3),
	},
	linkSecondary: {
		color: theme.palette.secondary.main,
	},
});

const AppHeaderAuth = ({classes, authUser = null}) => (
	<React.Fragment>
		<div className={classes.right}>
			<Link
				color="inherit"
				variant="h6"
				underline="none"
				className={classes.rightLink}
				component={(props) => (<RouterLink to={ROUTES.PUBLIC_PLACES} {...props}/>)}>
				PANAMÁ
			</Link>
			{/*<AdminButton className={classes.rightLink}
						 extras={{
							 color    : "inherit",
							 variant  : "h6",
							 underline: "none",
						 }}/>*/}
			<SignOutButton className={classes.rightLink}
						   extras={{
							   color    : "inherit",
							   variant  : "h6",
							   underline: "none",
						   }}/>
			{!authUser && (
				<React.Fragment>
					{/*
					<Link
						color="inherit"
						variant="h6"
						underline="none"
						className={classes.rightLink}
						component={(props) => (<RouterLink to={ROUTES.SIGN_IN} {...props}/>)}>
						{'Sign In'}
					</Link>
					<Link
						variant="h6"
						underline="none"
						className={classNames(classes.rightLink, classes.linkSecondary)}
						component={(props) => (<RouterLink to={ROUTES.SIGN_UP} {...props}/>)}>
						{'Sign Up'}
					</Link>
					*/}
				</React.Fragment>
			)}
		</div>
	</React.Fragment>
);

AppHeaderAuth.propTypes = {
	classes : PropTypes.object.isRequired,
	authUser: PropTypes.object,
	firebase: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withRouter,
	withAuthentication)(AppHeaderAuth);
