import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../../constants/theme/public';

function withPublicTheme(Component) {
	function WithPublicTheme(props) {
		// MuiThemeProvider makes the theme available down the React tree thanks to React context.
		return (
			<MuiThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				<Component {...props} />
			</MuiThemeProvider>
		);
	}

	return WithPublicTheme;
}

export default withPublicTheme;
