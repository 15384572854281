export const USER = 'user';
export const BUSINESS = 'business';
export const ADMIN = 'admin';
export const SU = 'su';

export default {
	USER,
	BUSINESS,
	ADMIN,
	SU
}
