export const AUTH = '/auth';
export const SIGN_IN = '/auth/signin';
export const SIGN_UP = '/auth/signup';
export const PASSWORD_FORGET = '/auth/pw-forget';

export const HOME = '/';
export const LANDING = '/';

export const ADMIN = '/admin/';
export const ACCOUNT = '/admin/account';
export const PLACES = '/admin/places';
export const PLACES_LIST = '/admin/places/list';
export const PLACES_ADD = '/admin/places/add';
export const PLACES_EDIT = '/admin/places/edit/:placeId';

export const SUGGESTED_PLACES = '/admin/suggested-places';
export const SUGGESTED_PLACES_LIST = '/admin/suggested-places/list';
export const SUGGESTED_PLACES_EDIT = '/admin/suggested-places/edit/:placeId';

export const PUBLIC_PLACES = '/places';
export const PUBLIC_PLACES_VIEW = '/places/:placeId';

export const PUBLIC = '/';
export const PRIVATE = '/admin';

export default {
	PUBLIC,

	LANDING,
	HOME,

	AUTH,
	SIGN_UP,
	SIGN_IN,
	PASSWORD_FORGET,

	PRIVATE,

	ACCOUNT,
	ADMIN,

	PLACES,
	PLACES_LIST,
	PLACES_ADD,
	PLACES_EDIT,

	SUGGESTED_PLACES,
	SUGGESTED_PLACES_LIST,
	SUGGESTED_PLACES_EDIT,

	PUBLIC_PLACES,
	PUBLIC_PLACES_VIEW,
}
