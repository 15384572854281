import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';

import {usePlace} from "../../../hooks/usePlace";
import {Loading} from "../../../atoms/Loading";

import PlacesComponent from './places.component';

const useStyles = makeStyles((theme) => createStyles({
}));


export const Place = ({match, ...props}) => {
    const classes = useStyles();
    const placeId = match.params?.placeId;
    const {error, loading, place} = usePlace(placeId);

    console.log({error, loading, place});

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <h1>{place.name}</h1>

            <div className={classes.spinner}>
                <div className={classes.loading}>
                    <div/>
                    <div/>
                </div>
            </div>
        </>
    );
};
export default Place;
