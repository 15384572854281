import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
    '@keyframes loading': {
        '0%': {
            top: '96px',
            left: '96px',
            width: 0,
            height: 0,
            opacity: 1,
        },
        '100%': {
            top: '18px',
            left: '18px',
            width: '156px',
            height: '156px',
            opacity: 0,
        }
    },
    spinner: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        width: '156px',
        height: '156px',
        position: 'relative',
        transform: 'translateZ(0) scale(1)',
        backfaceVisibility: 'hidden',
        transformOrigin: '0 0', /* see note above */
        '& div': {
            position: 'absolute',
            borderWidth: '4px',
            borderStyle: 'solid',
            opacity: 1,
            borderRadius: '50%',
            animation: '$loading 2.4s cubic-bezier(0,0.2,0.8,1) infinite',
            '&:nth-child(1)': {
                borderColor: '#74007a'
            },
            '&:nth-child(2)': {
                borderColor: '#a53faa',
                animationDelay: '-1.20s'
            }
        }
    }
}));


export const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.spinner}>
            <div className={classes.loading}>
                <div/>
                <div/>
            </div>
        </div>
    );
};
export default Loading;
