import React, {Component} from 'react';
import {BrowserRouter as Router,} from 'react-router-dom';

import "./utils/db";

import Firebase, {FirebaseContext} from './decorators/firebase';
import PaperBase from './containers';

export default class App extends Component {
	render() {
		return (
			<FirebaseContext.Provider value={new Firebase()}>
				<Router>
					<PaperBase/>
				</Router>
			</FirebaseContext.Provider>
		);
	}
}

// https://angularfirebase.com/lessons/role-based-authorization-with-firestore-nosql-and-angular-5/#Rules-for-the-User-Document
// https://firebase.google.com/docs/firestore/query-data/get-data
// https://medium.com/@scarygami/cloud-firestore-quicktip-documentsnapshot-vs-querysnapshot-70aef6d57ab3
// https://github.com/the-road-to-react-with-firebase/react-firebase-authentication/blob/deploy-security-rules/src/components/Firebase/firebase.js


// https://firebase.google.com/docs/functions/firestore-events
// https://egghead.io/lessons/react-create-dynamic-types-and-use-type-composition-to-extract-common-functionality
// https://github.com/GeekyAnts/mobx-state-tree-firebase
// https://github.com/suevalov/next-blog-firestore/blob/master/stores/categoriesStore.js
// https://firebase.google.com/docs/guides
// https://github.com/the-road-to-react-with-firebase/react-firebase-authentication/blob/deploy-security-rules/src/components/Messages/Messages.js


// https://egghead.io/courses/design-systems-with-react-and-typescript-in-storybook
// https://blog.maximeheckel.com
// https://blog.maximeheckel.com/posts/switching-off-the-lights-adding-dark-mode-to-your-react-app-with-context-and-hooks-f41da6e07269
