import React from 'react';
import {Route, Switch} from "react-router-dom";

import AdminRoute from '../components/shared/AdminRoute';
import * as ROUTES from "../constants/routes";

import Admin from "./admin";
import Home from "./public/home";
import Auth from './public/auth';
import withAuthentication from "../decorators/auth/withAuthentication";

const Main = () => (
	<Switch>
		<Route exact path={ROUTES.PUBLIC} component={Home}/>
		<Route path={ROUTES.AUTH} component={Auth}/>
		<AdminRoute path={ROUTES.PRIVATE} component={Admin}/>
		<Route path={ROUTES.PUBLIC_PLACES} component={Home}/>
	</Switch>
);

export default withAuthentication(Main)
