import React, {Component, Fragment} from 'react';

import {withStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import HelpIcon from "@material-ui/core/SvgIcon/SvgIcon";
import SearchIcon from "@material-ui/core/SvgIcon/SvgIcon";
import RefreshIcon from "@material-ui/icons/Refresh";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import TextField from "@material-ui/core/TextField/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {withFirebase} from '../../decorators/firebase';
import Container from '../../components/admin/Container';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
	secondaryBar: {
		zIndex: 0,
	},
	link        : {
		textDecoration: 'none',
		color         : lightColor,
		'&:hover'     : {
			color: theme.palette.common.white,
		},
	},
	mainContent : {
		flex      : 1,
		padding   : '48px 36px 0',
		background: '#eaeff1',
	},
	searchInput : {
		fontSize: theme.typography.fontSize,
	},
	block       : {
		display: 'block',
	},
	addUser     : {
		marginRight: theme.spacing(),
	},
});

@withStyles(styles)
@withFirebase
class AdminPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading : false,
			users   : [],
			anchorEl: null,
		};
	}

	componentDidMount() {
		this.setState({loading: true});
		this.props.firebase.users().on('value', snapshot => {
			const usersObject = snapshot.val();

			const usersList = Object.keys(usersObject).map(key => ({
				...usersObject[key],
				uid: key,
			}));

			this.setState({
				users  : usersList,
				loading: false,
			});
		});
	}

	componentWillUnmount() {
		this.props.firebase.users().off();
	}

	handleClick = event => {
		this.setState({anchorEl: event.currentTarget});
	};

	handleClose = () => {
		this.setState({anchorEl: null});
	};

	render() {
		const {classes} = this.props;
		const {users, loading, anchorEl} = this.state;

		return (
			<Fragment>
				<AppBar
					component="div"
					className={classes.secondaryBar}
					color="primary"
					position="static"
					elevation={0}>
					<Toolbar>
						<Grid container alignItems="center" spacing={8}>
							<Grid item xs>
								<Typography color="inherit" variant="h5">
									Admin
								</Typography>
							</Grid>
							<Grid item>
								<Button className={classes.button} variant="outlined" color="inherit" size="small">
									Web setup
								</Button>
							</Grid>
							<Grid item>
								<Tooltip title="Help">
									<IconButton color="inherit">
										<HelpIcon/>
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<AppBar
					component="div"
					className={classes.secondaryBar}
					color="primary"
					position="static"
					elevation={0}>
					<Tabs value={0} textColor="inherit">
						<Tab textColor="inherit" label="Users"/>
						<Tab textColor="inherit" label="Sign-in method"/>
						<Tab textColor="inherit" label="Templates"/>
						<Tab textColor="inherit" label="Usage"/>
					</Tabs>
				</AppBar>

				<main className={classes.mainContent}>
					{loading && <div>Loading ...</div>}
					<Container
						header={<Toolbar>
							<Grid container spacing={8} alignItems="center">
								<Grid item>
									<SearchIcon className={classes.block} color="inherit"/>
								</Grid>
								<Grid item xs>
									<TextField
										fullWidth
										placeholder="Search by email address, phone number, or user UID"
										InputProps={{
											disableUnderline: true,
											className       : classes.searchInput,
										}}
									/>
								</Grid>
								<Grid item>
									<Button variant="contained" color="primary" className={classes.addUser}>
										Add user
									</Button>
									<Tooltip title="Reload">
										<IconButton>
											<RefreshIcon className={classes.block} color="inherit"/>
										</IconButton>
									</Tooltip>
									<Tooltip title="Options">
										<IconButton
											aria-owns={anchorEl ? 'simple-menu' : undefined}
											aria-haspopup="true"
											onClick={this.handleClick}>
											<MoreVertIcon className={classes.block} color="inherit"/>
										</IconButton>
									</Tooltip>
								</Grid>

								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={this.handleClose}>
									<MenuItem onClick={this.handleClose}>Profile</MenuItem>
									<MenuItem onClick={this.handleClose}>My account</MenuItem>
									<MenuItem onClick={this.handleClose}>Logout</MenuItem>
								</Menu>
							</Grid>
						</Toolbar>}>
						<UserList users={users} classes={classes}/>
					</Container>
				</main>
			</Fragment>
		);
	}
}

const UserList = ({classes, users}) => {
	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell>UID</TableCell>
					<TableCell>Name</TableCell>
					<TableCell>Email</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{users.map(user => {
					return (
						<TableRow key={user.uid}>
							<TableCell component="th" scope="row">{user.uid}</TableCell>
							<TableCell>{user.username}</TableCell>
							<TableCell>{user.email}</TableCell>
						</TableRow>
					);
				})}
			</TableBody>

		</Table>
	);
};

export default AdminPage;
