import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {LazyLoadImage}  from 'react-lazy-load-image-component';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';
import StarRatings from 'react-star-ratings';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

import {Typography} from '../../../common';

const useStyles = makeStyles((theme) => createStyles({
    paper: {},
    item: {},
    item__container: {
        minWidth: '320px',
        borderRadius: '4px',
        textDecoration: 'none',
    },
    title: {
        marginBottom: theme.spacing(14),
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    image__container: {
        padding: '0',
        position: 'relative',
        '& .carousel .control-dots': {
            paddingInlineStart: 0,
            '& .dot': {
                margin: '0 2px',
                width: '6px',
                height: '6px',
                transition: 'all 0.7s',
                '&.selected': {
                    width: '8px',
                    height: '8px',
                },
            }
        },
        '&:hover': {},
    },
    images__options: {
        height: 0,
    },
    image: {
        width: '100%',
        height: 260,
        // marginTop   : theme.spacing(4),
        // marginBottom: theme.spacing(4),
    },
    image__overlay: {
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        // bottom  : 0,
        padding: '10px',
    },
    image__icons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    image__icon: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        backgroundColor: '#F5F3EC',
        marginRight: '5px',
        transition: 'all 0.7s',
        '& svg': {
            fill: '#5C5A56',
        },
    },
    button: {
        marginTop: theme.spacing(8),
    },
    information__content: {
        padding: '12px 16px'
    },
    price_container: {},
    price_value: {
        textAlign: 'right',
        fontWeight: 800,
        color: '#464646',
    },
    price_secondary: {
        textTransform: 'uppercase',
        fontSize: '10px',
        lineHeight: '1.3',
        fontWeight: 'bold',
        textAlign: 'right',
    },
    rating__value: {
        backgroundColor: '#FFAB00',
        margin: '0 5px 0 0',
        color: 'white',
        fontWeight: 'bold',
        padding: '2px 3px',
        borderRadius: '3px',
    },
    images__carrousel: {
        overflow: 'hidden',
        position: 'relative',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        borderRadius: '4px 4px 0 0',
        transform: 'translateZ(0)'
    },

}));

// https://mui-treasury.com
// https://medium.com/@rossbulat/how-to-memoize-in-react-3d20cbcd2b6e


const Images = ({name, images, classes}) => {
	const children = images.map((image, index) => (
		<LazyLoadImage
			key={`place--image-${index}`}
			src={image}
			alt={`${name} ${index}`}
			loading="lazy"
            placeholderSrc={'https://via.placeholder.com/400'}
			className={classes.image}
			width="300"
			height="260"
		/>
	));

    if (images.length === 1) {
        return (<div>
            {children}
        </div>);
    }
    return (
        <Carousel showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  autoPlay
                  swipeable>
            {children}
        </Carousel>
    );
};

function PlaceItem2Component({place}) {
    const classes = useStyles();
    const activities = (activities, length = 4) => {
        if (activities.length > length) {
            activities = activities.slice(0, length - 1);
        }
        return (
            <Typography variant="body2" gutterBottom color="textSecondary">
                {activities.join(' • ')}
            </Typography>
        )
    };

    const images = () => {
        return (
            <Grid item className={classes.image__container} component='div'>
                {place.images && (
                    <div className={classes.images__carrousel}>

                        <Images images={place.images} classes={classes} name={place.name}/>
                        <div className={classes.images__options}>

                        </div>
                    </div>
                )}

                <div className={classes.image__overlay}>
                    <div className={classes.image__icons}>
                        <div className={classes.image__icon}>
                            <ShareIcon/>
                        </div>
                        <div className={classes.image__icon}>
                            <FavoriteBorderIcon/>
                        </div>
                    </div>
                </div>
            </Grid>
        )
    };

    const prices = () => {
        return (
            <Grid item className={classes.price_container} component='div'>
                <Typography variant="body2" color="textSecondary" className={classes.price_secondary}>
                    Desde
                </Typography>
                <Typography variant="subtitle1" className={classes.price_value}>$28.00</Typography>
                <Typography variant="body2" color="textSecondary" className={classes.price_secondary}>
                    $19/Comida
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.price_secondary}>
                    $7/Entrada
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.price_secondary}>
                    $2/Metro
                </Typography>
            </Grid>
        );
    };

    const information = () => {
        return (
            <Grid item xs={12} sm container className={classes.information__content} component='div'>
                <Grid item xs container direction="column" component='div'>
                    <Grid item xs component='div'>
                        <Typography gutterBottom variant="subtitle1" noWrap>
                            {place.name}
                        </Typography>
                        <div>
                            <Typography variant="body2" color="textSecondary" component="span"
                                        className={classes.rating__value}>
                                {(4.3)}
                            </Typography>
                            <StarRatings rating={4.3}
                                         starRatedColor="#FFAB00"
                                         numberOfStars={5}
                                         starDimension="16px"
                                         starSpacing="0px"
                                         name='rating'/>
                            <Typography variant="body2" color="textSecondary" component="span">
                                {`(${21})`}
                            </Typography>
                        </div>
                        <Typography variant="body2" color="textSecondary">
                            {place.address}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {place.duration}
                        </Typography>
                    </Grid>
                </Grid>

                {prices()}
            </Grid>
        );
    };

    return (
        <Grid item container xs={12} md={3} sm={6} className={classes.item__container} component="a" href={`/places/${place.uid}`}>
            <div className={classes.item}>
                <Paper className={classes.paper}>
                    <Grid container component="div">
                        {images()}
                        {information()}
                    </Grid>
                </Paper>
            </div>
        </Grid>);
}

export const PlaceItem2 = PlaceItem2Component;
export default PlaceItem2;

/*
<Grid item xs={12} sm container>
						<Typography variant="h5" align="center">
							{place.name}
						</Typography>
					</Grid>
 */
