import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import '../../styles/libs/masterslider/masterslider.css';

let $ = window.$;

class MasterSlider extends Component {
	static propTypes = {
		id   : PropTypes.string.isRequired,
		theme: PropTypes.string

	};
	static defaultProps = {
		theme: "ms-skin-default"
	};

	constructor(props) {
		super(props);
		this.masterSlider = React.createRef();
	}

	componentDidMount() {
		if (this.masterSlider) {
			const {id} = this.props;
			const ms = new window.MasterSlider();

			// slider controls
			ms.control('arrows', {autohide: true, overVideo: true});
			ms.control('bullets', {
				autohide : false,
				overVideo: true,
				dir      : 'h',
				align    : 'bottom',
				space    : 6,
				margin   : 15
			});
			
			// slider setup
			ms.setup(id, {
				width             : 1180,
				height            : 820,
				minHeight         : 0,
				space             : 0,
				start             : 1,
				grabCursor        : true,
				swipe             : true,
				mouse             : false,
				keyboard          : false,
				layout            : 'fullwidth',//fullwidth, boxed
				wheel             : false,
				autoplay          : true,
				instantStartLayers: true,
				loop              : true,
				shuffle           : false,
				preload           : 0,
				heightLimit       : true,
				autoHeight        : false,
				smoothHeight      : true,
				endPause          : false,
				overPause         : true,
				fillMode          : "fill",
				centerControls    : true,
				startOnAppear     : false,
				layersMode        : "center",
				autofillTarget    : "",
				hideLayers        : true,
				fullscreenMargin  : 0,
				speed             : 50,
				dir               : "h",
				parallaxMode      : 'swipe',
				view              : "fade"
			});

			$("head").append("<link rel='stylesheet' id='ms-fonts'  href='//fonts.googleapis.com/css?family=Open+Sans Condensed:700,300|Open+Sans:800' type='text/css' media='all' />");

			window.masterslider_instances = window.masterslider_instances || [];
			window.masterslider_instances.push(ms);
		}
	}

	render() {
		const {id, children, theme} = this.props;
		return (
			<div className="master-slider-parent ms-parent-id-1">
				<div ref={this.masterSlider} id={id} className={classNames("master-slider", theme)}>
					{children}
				</div>
			</div>
		);
	}
}

export default MasterSlider;
