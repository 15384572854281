import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import withAuthentication from "../../decorators/auth/withAuthentication";

import {containsSome} from "../../services/array.service";

import ROLES from "../../constants/roles";
import ROUTES from "../../constants/routes";

const AdminRoute = ({authUser, component: Component, ...props}) => (
	<Route {...props} render={props => (
		!!authUser && containsSome([ROLES.ADMIN, ROLES.SU], authUser._roles)
		? <Component {...props} />
		: <Redirect to={ROUTES.SIGN_IN}/>
	)}/>);

export default withAuthentication(AdminRoute);
