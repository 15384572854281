import * as app from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'
import 'firebase/storage';
import COLLECTIONS from "./collections";

export default class Firebase {
	constructor() {
		/* Helper */

		this.serverValue = app.database.ServerValue;
		this.emailAuthProvider = app.auth.EmailAuthProvider;

		/* Firebase APIs */
		this.app = app;
		this.auth = app.auth();
		this.db = app.database();
		this.dbstore = app.firestore();
		this.bucket = app.storage;

		/* Social Sign In Method Provider */

		this.googleProvider = new app.auth.GoogleAuthProvider();
		this.facebookProvider = new app.auth.FacebookAuthProvider();
		this.twitterProvider = new app.auth.TwitterAuthProvider();
	}

	// *** Auth API ***

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignInWithGoogle = () =>
		this.auth.signInWithPopup(this.googleProvider);

	doSignInWithFacebook = () =>
		this.auth.signInWithPopup(this.facebookProvider);

	doSignInWithTwitter = () =>
		this.auth.signInWithPopup(this.twitterProvider);

	doSignOut = () => this.auth.signOut();

	doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

	doSendEmailVerification = () =>
		this.auth.currentUser.sendEmailVerification({
			url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
		});

	doPasswordUpdate = password =>
		this.auth.currentUser.updatePassword(password);

	// *** Merge Auth and DB User API *** //

	onAuthUserListener = (next, fallback) =>
		this.auth.onAuthStateChanged(authUser => {
			if (authUser) {
				this.user(authUser.uid).get()
					.then(user => {
						if (!user.exists)
							return fallback();

						const me = user.data();
						return next({
							uid          : authUser.uid,
							email        : authUser.email,
							emailVerified: authUser.emailVerified,
							providerData : authUser.providerData,
							displayName  : authUser.displayName,
							photoURL     : authUser.photoURL,
							...me,
							_roles       : Object.keys(me.roles)
						});
					})
					.catch(err => {
						console.log('err', err);
						fallback();
					});
			} else {
				fallback();
			}
		});

	// *** Storage API ***

	// *** User API ***

	// user = uid => this.db.ref(`users/${uid}`);
	//
	// users = () => this.db.ref('users');

	users = () => this.dbstore.collection(COLLECTIONS.USERS);
	user = uid => this.dbstore.collection(COLLECTIONS.USERS).doc(uid);

	// *** Place API ***

	places = () => this.dbstore.collection(COLLECTIONS.PLACES);
	place = uid => this.dbstore.collection(COLLECTIONS.PLACES).doc(uid);

	// *** Place API ***

	suggestedPlaces = () => this.dbstore.collection(COLLECTIONS.SUGGESTED_PLACES);
	suggestedPlace = uid => this.dbstore.collection(COLLECTIONS.SUGGESTED_PLACES).doc(uid);
};
