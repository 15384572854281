import React from 'react';
import classNames from 'classnames';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import PublicIcon from '@material-ui/icons/Public';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PowerIcon from '@material-ui/icons/Power';
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";

import withAuthentication from "../../decorators/auth/withAuthentication";
import ROUTES from '../../constants/routes';
import ROLES from '../../constants/roles';
import SignOutButton from "../shared/SignOutButton";
import withRole from "../../decorators/auth/withRole";

const items = [
	{text: 'Perfil', icon: <PeopleIcon/>},
	{text: 'Sitio', icon: <PublicIcon/>, component: RouterLink, to: ROUTES.PUBLIC},
	{text: 'Salir', icon: <PowerIcon/>, component: SignOutButton},
];

const styles = theme => ({
	item          : {
		paddingTop   : 4,
		paddingBottom: 4,
		color        : 'rgba(255, 255, 255, 0.7)',
	},
	itemCategory  : {
		backgroundColor: '#232f3e',
		boxShadow      : '0 -1px 0 #404854 inset',
		paddingTop     : 16,
		paddingBottom  : 16,
	},
	itemProfile   : {
		padding: '10px 15px',
	},
	itemActionable: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	itemActiveItem: {
		color: '#4fc3f7',
	},
	itemPrimary   : {
		color        : 'inherit',
		// fontSize     : theme.typography.fontSize,
		// '&$textDense': {
		// 	fontSize: theme.typography.fontSize,
		// },
	},
	textDense     : {}
});

@withRouter
@withStyles(styles)
@withAuthentication
@withRole([ROLES.SU, ROLES.ADMIN])
class NavigatiorProfileMenu extends React.Component {
	state = {
		openProfileMenu: false,
	};

	handleClick = () => {
		this.setState(state => ({openProfileMenu: !state.openProfileMenu}));
	};

	render() {
		const {classes, authUser} = this.props;
		return (
			<div>
				<ListItem className={classNames(classes.item, classes.itemCategory)}
						  button onClick={this.handleClick}>
					<ListItemIcon>
						<Avatar className={classes.avatar} src={authUser.photoURL || "/images/avatar/1.jpg"}/>
					</ListItemIcon>
					<ListItemText
						classes={{
							primary: classes.itemPrimary,
						}}>
						{authUser.displayName || `${authUser.firstName} ${authUser.lastName}` || 'User Name'}
					</ListItemText>
					{this.state.openProfileMenu ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
				</ListItem>
				<Collapse in={this.state.openProfileMenu} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{items.map(({text, icon, ...props}) => (
							<ListItem key={text} button className={classNames(
								classes.item,
								classes.itemActionable,
								classes.itemProfile
							)} {...props}>
								<ListItemIcon>
									{icon}
								</ListItemIcon>
								<ListItemText primary={text} classes={{
									primary  : classes.itemPrimary,
								}}/>
							</ListItem>
						))}
					</List>
				</Collapse>
			</div>
		);
	}
}

export default NavigatiorProfileMenu;
