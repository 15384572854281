import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import TextField from "@material-ui/core/TextField/TextField";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {withFirebase} from '../../decorators/firebase';
import Container from '../../components/admin/Container';
import PlaceContainer from './components/container';
import List from './components/list';
import * as ROUTES from "../../constants/routes";

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => {
	// console.log(theme);
	return {
		secondaryBar: {
			zIndex: 0,
		},
		link        : {
			textDecoration: 'none',
			color         : lightColor,
			'&:hover'     : {
				color: theme.palette.common.white,
			},
		},
		mainContent : {
			flex      : 1,
			padding   : '48px 36px 0',
			background: '#eaeff1',
		},
		searchInput : {
			fontSize: theme.typography.fontSize,
		},
		block       : {
			display: 'block',
		},
		addUser     : {
			marginRight: theme.spacing(),
		}
	};
};

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

@withStyles(styles)
@withFirebase
class SuggestedPlacesList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading        : false,
			suggestedPlaces: [],
			anchorEl       : null,
			selectedTab    : 0
		};
	}

	componentDidMount() {
		this.setState({loading: true});
		this.listener = this.props.firebase.suggestedPlaces()
			.onSnapshot(querySnapshot => {
					// querySnapshot.forEach((doc) => {
					// 	console.log('doc', doc, doc.ref, doc.id, doc.data());
					// });
					const suggestedPlacesList = querySnapshot.docs.map(doc => {
						const data = doc.data();
						// data.default_language.get()
						// 	.then(doc => {
						// 		if (doc.exists) {
						// 			data.language = doc.data();
						// 			console.log("Document data:", doc.data());
						// 		} else {
						// 			// doc.data() will be undefined in this case
						// 			console.log("No such document!");
						// 		}
						// 	}).catch((error) => console.log("Error getting document:", error));
						return {
							...data,
							uid: doc.id,
						}
					});

					this.setState({
						suggestedPlaces: suggestedPlacesList,
						loading        : false
					});
				},
				err => console.error(err),
				() => this.setState({loading: false}));
	}

	componentWillUnmount() {
		this.listener();
	}

	handleClick = event => {
		this.setState({anchorEl: event.currentTarget});
	};

	handleClose = () => this.setState({anchorEl: null});

	handleAddClick = () => this.setState({openAddDialog: true});

	handleTabChange = (event, newValue) => {
		this.setState({selectedTab: newValue});
	};

	render() {
		const {classes} = this.props;
		const {suggestedPlaces, loading, anchorEl, selectedTab} = this.state;

		return (
			<PlaceContainer>
				<AppBar
					component="div"
					className={classes.secondaryBar}
					color="primary"
					position="static"
					elevation={0}>
					<Tabs value={selectedTab} onChange={this.handleTabChange} textColor="inherit">
						<Tab textColor="inherit" label="Users"/>
						<Tab textColor="inherit" label="Sign-in method"/>
						<Tab textColor="inherit" label="Templates"/>
						<Tab textColor="inherit" label="Usage"/>
					</Tabs>
				</AppBar>

				<main className={classes.mainContent}>
					{loading && <div>Loading ...</div>}
					{selectedTab === 0 && (
						<Container
							header={<Toolbar>
								<Grid container spacing={8} alignItems="center">
									<Grid item>
										<SearchIcon className={classes.block} color="inherit"/>
									</Grid>
									<Grid item xs>
										<TextField
											fullWidth
											placeholder="Search by email address, phone number, or user UID"
											InputProps={{
												disableUnderline: true,
												className       : classes.searchInput,
											}}
										/>
									</Grid>
									<Grid item>
										<Button variant="contained" color="primary" className={classes.addUser}
												component={AdapterLink}
												to={ROUTES.PLACES_ADD}>
											Add Place
										</Button>
										<Tooltip title="Reload">
											<IconButton>
												<RefreshIcon className={classes.block} color="inherit"/>
											</IconButton>
										</Tooltip>
										<Tooltip title="Options">
											<IconButton
												aria-owns={anchorEl ? 'simple-menu' : undefined}
												aria-haspopup="true"
												onClick={this.handleClick}>
												<MoreVertIcon className={classes.block} color="inherit"/>
											</IconButton>
										</Tooltip>
									</Grid>

									<Menu
										id="simple-menu"
										anchorEl={anchorEl}
										open={Boolean(anchorEl)}
										onClose={this.handleClose}>
										<MenuItem onClick={this.handleClose}>Profile</MenuItem>
										<MenuItem onClick={this.handleClose}>My account</MenuItem>
										<MenuItem onClick={this.handleClose}>Logout</MenuItem>
									</Menu>
								</Grid>
							</Toolbar>}>
							<List suggestedPlaces={suggestedPlaces}/>
						</Container>
					)}
					{selectedTab === 1 && <React.Fragment>Item Two</React.Fragment>}
					{selectedTab === 2 && <React.Fragment>Item Three</React.Fragment>}
				</main>
			</PlaceContainer>
		);
	}
}

export default SuggestedPlacesList;
