import React, {useState, useEffect} from 'react';
import {Marker} from '@react-google-maps/api';

import {point2LatLng} from '../../../../utils/geo';
import {PlaceOverlayView} from './overlayView';

export const PlaceWithMarkerAndOverlayView = ({place, selected, onMarkerClick, ...props}) => {
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    useEffect(() => {
        setOverlayVisible(selected);
    }, [selected]);

    const _onClick = () => {
        onMarkerClick(place.uid);
        setOverlayVisible(p => !p);
    };

    return (
        <>
            <Marker position={point2LatLng(place.location)} clickable onClick={_onClick}/>
            <PlaceOverlayView place={place} visible={isOverlayVisible}/>
        </>
    );
};


