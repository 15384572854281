import React, {useState} from 'react';
import {GoogleMap, LoadScript} from '@react-google-maps/api';

import mapStyleDay from './styles/day';
import mapStyleNigth from './styles/night';

import {isNigth} from '../../../../utils/time';

import {PlaceWithMarkerAndOverlayView} from '../Place';

export const PlacesMap = ({places = [], center, className, style}) => {
    const [selected, setSelected] = useState(undefined);
    const _onMarkerClick = (uid) => setSelected(uid);
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyDQbFCMF9wALwhIVhDUKlYCS9vzMssjVTU">
            <GoogleMap
                options={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'auto',
                    scrollwheel: false,
                    streetViewControl: false,
                    styles: isNigth() ? mapStyleNigth : mapStyleDay,
                }}
                mapContainerClassName={className}
                mapContainerStyle={style}
                zoom={13}
                center={center}
                onClick={() => setSelected(undefined)}>
                {places.map(place => (
                    <PlaceWithMarkerAndOverlayView
                        key={`place_${place.uid}`}
                        place={place}
                        selected={selected === place.uid}
                        onMarkerClick={_onMarkerClick}/>
                ))}
            </GoogleMap>
        </LoadScript>)
};


