import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';

const styles = theme => ({
	root: {
		color: theme.palette.common.white,
	},
});

function AppBarComponent(props) {
	return <MuiAppBar elevation={3} position="static" {...props} />;
}

AppBarComponent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export const AppBar = withStyles(styles)(AppBarComponent);
export default AppBar;
