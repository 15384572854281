import React from 'react';
import PropTypes from 'prop-types';

const Slide = ({delay, fillMode, image, children}) => (
	<div className="ms-slide" data-delay={delay} data-fill-mode={fillMode}>
		<img src={image} alt="slide" data-src={image}/>
		{children}
	</div>
);

Slide.propTypes = {
	delay   : PropTypes.number,
	fillMode: PropTypes.string,
	image   : PropTypes.string.isRequired,
};

Slide.defaultProps = {
	delay   : 7,
	fillMode: 'fill'
};

export default Slide;
