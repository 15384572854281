import React from 'react';
import {withStyles} from '@material-ui/core/styles';

// https://loading.io/css/
const styles = theme => ({
	container             : {
		display       : 'flex',
		alignItems    : 'center',
		justifyContent: 'center',
		width         : '100%',
	},
	dual_ring             : {
		display  : 'inline-block',
		width    : '64px',
		height   : '64px',
		'&:after': {
			content     : " ",
			display     : 'block',
			width       : '46px',
			height      : '46px',
			margin      : '1px',
			borderRadius: '50%',
			border      : '5px solid #fff',
			borderColor : '#fff transparent #fff transparent',
			animation   : 'dual-ring 1.2s linear infinite',
		}
	},
	'@keyframes dual-ring': {
		'0%'  : {
			transform: 'rotate(0deg)',
		},
		'100%': {
			transform: 'rotate(360deg)'
		}
	},
});

const DualRingLoaderComponent = ({classes}) => (
	<div className={classes.container}>
		<div className={classes.ellipsis}/>
	</div>
);
export const DualRingLoader = withStyles(styles)(DualRingLoaderComponent);

export default DualRingLoader;
