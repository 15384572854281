import React, {Fragment} from 'react';
import {compose} from 'recompose';

import {withStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import HelpIcon from "@material-ui/icons/Help";

const styles = theme => {
	// console.log(theme);
	return {
		secondaryBar: {
			zIndex: 0,
		},
	};
};

const Container = ({classes, children}) => (
	<Fragment>
		<AppBar
			component="div"
			className={classes.secondaryBar}
			color="primary"
			position="static"
			elevation={0}>
			<Toolbar>
				<Grid container alignItems="center" spacing={8}>
					<Grid item xs>
						<Typography color="inherit" variant="h5">
							Places
						</Typography>
					</Grid>
					<Grid item>
						<Button className={classes.button} variant="outlined" color="inherit" size="small">
							Web setup
						</Button>
					</Grid>
					<Grid item>
						<Tooltip title="Help">
							<IconButton color="inherit">
								<HelpIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
		{children}
	</Fragment>
);

export default compose(
	withStyles(styles)
)(Container)
