import React from 'react';
import {asField} from 'informed';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {MenuItem, withStyles} from "@material-ui/core";
import {compose} from "recompose";

// https://github.com/joepuzzo/informed/blob/master/src/components/form-fields/Select.js
const styles = theme => ({
	form_control: {
		width: '100%',
	},
});

export default compose(
	withStyles(styles),
	asField)(
	({classes, fieldApi, fieldState, ...props}) => {
		const {value} = fieldState;
		const {setTouched, setValue} = fieldApi;
		const {
			onChange,
			onBlur,
			field,
			initialValue,
			children,
			forwardedRef,
			multiple,
			label,
			helperText,
			options,
			id = String(Math.floor(Math.random() * 1e8)),
			...rest
		} = props;

		return (
			<FormControl error={!!fieldState.error} className={classes.form_control}>
				<InputLabel htmlFor={id}>{label}</InputLabel>
				<Select
					{...rest}
					multiple={multiple}
					name={field}
					ref={forwardedRef}
					value={value || (multiple ? [] : "")}
					onChange={e => {
						setValue(e.target.value);

						if (onChange) {
							onChange(e.target.value);
						}
					}}
					inputProps={{
						id,
						onBlur: e => {
							setTouched();
							if (onBlur) {
								onBlur(e);
							}
						}
					}}>
					{options.map(option => (
						<MenuItem value={option.value} key={option.value}>
							{option.text}
						</MenuItem>
					))}
				</Select>
				<FormHelperText>{fieldState.error || helperText}</FormHelperText>

			</FormControl>
		);
	});
