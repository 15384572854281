import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MuiPaper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import capitalize from "@material-ui/core/utils/capitalize";

const styles = theme => ({
	backgroundLight: {
		backgroundColor: theme.palette.secondary.light,
	},
	backgroundMain : {
		backgroundColor: theme.palette.secondary.main,
	},
	backgroundDark : {
		backgroundColor: theme.palette.secondary.dark,
	},
	padding        : {
		padding: theme.spacing(),
	},
});

function Paper(props) {
	const {background, classes, className, padding, ...other} = props;
	return (
		<MuiPaper
			elevation={0}
			square
			className={classNames(
				classes[`background${capitalize(background)}`],
				{
					[classes.padding]: padding,
				},
				className,
			)}
			{...other}
		/>
	);
}

Paper.propTypes = {
	background: PropTypes.oneOf(['light', 'main', 'dark']),
	classes   : PropTypes.object.isRequired,
	className : PropTypes.string,
	padding   : PropTypes.bool,
};

Paper.defaultProps = {
	background: 'light',
	padding   : false,
};

export default withStyles(styles)(Paper);
