import React from 'react';
import {useField} from 'informed';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => createStyles({
	form_control: {
		width: '100%',
	},
}));


export default (props) => {
	const classes = useStyles();
	const {fieldState, fieldApi, render, ref, userProps, informed} = useField(props);
	const {maskedValue} = fieldState;
	const {setValue, setTouched} = fieldApi;
	const {onChange, onBlur, ...informedProps} = informed;
	const {
		helperText,
		id = String(Math.floor(Math.random() * 1e8)),
		inputProps = {},
		...rest
	} = userProps;

	return render(
		<FormControl error={!!fieldState.error} className={classes.form_control}>
			<TextField
				{...rest}
				{...informedProps}
				ref={ref}
				value={!maskedValue && maskedValue !== 0 ? '' : maskedValue}
				onChange={e => {
					setValue(e.target.value, e);
					if (onChange) {
						onChange(e);
					}
				}}
				onBlur={e => {
					setTouched(true);
					if (onBlur) {
						onBlur(e);
					}
				}}
				inputProps={{
					...inputProps,
					id
				}}
			/>
			<FormHelperText>{fieldState.error || helperText}</FormHelperText>

		</FormControl>
	);
};
