import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {ArrayField, Form, Scope} from "informed";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from "@material-ui/icons/Remove";

import SelectFieldInformed from "./fields/selectFieldInformed";
import TextFieldInformed from "./fields/textFieldInformed";
import FileFieldInformed from "./fields/FileFieldInformed";

const useStyles = makeStyles((theme) => createStyles({
    flex: {
        display: 'flex',
    },
    field: {
        padding: '0 15px',
    },
}));

const DEFAULT_VALUES = {
    image: null,
    name: null,
    address: null,
    status: 'draft',
    history: null,
    know: null,
    transport: {
        own: null,
        public: null
    },
    activities: [],
    location: [],
};

const PlaceForm = ({initialValues, onSubmit}) => {
    const classes = useStyles();
    return (
        <Form onSubmit={onSubmit} initialValues={initialValues || DEFAULT_VALUES}>
            {({formState}) => (
                <Grid container spacing={8}>

                    <Grid item xs={12}>
                        <FileFieldInformed field='images' multiple validateOnChange validateOnBlur/>
                    </Grid>


                    <Grid container item xs={12}>
                        <Grid item xs={6} className={classes.field}>
                            <TextFieldInformed field='name'
                                               label='Nombre'
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {
                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.field}>
                            <TextFieldInformed field='duration'
                                               label='Duración del recorrido'
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {
                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                            />
                        </Grid>
                    </Grid>


                    <Grid container item xs={12}>
                        <Grid item xs={8} className={classes.field}>
                            <TextFieldInformed field='address'
                                               label='Dirección'
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {
                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.field}>
                            <SelectFieldInformed
                                field="status"
                                label={"Status"}
                                validateOnChange
                                validateOnBlur
                                options={[
                                    {text: "Draft", value: 'draft'},
                                    {text: "Publish", value: 'publish'},
                                    {text: "Archived", value: 'archived'},
                                    {text: "Deleted", value: 'deleted'},
                                ]}
                            />
                        </Grid>
                    </Grid>


                    <Grid item xs={12} className={classes.field}>
                        <TextFieldInformed field='history'
                                           label='Historia'
                                           multiline={true}
                                           rows={3}
                                           validateOnChange
                                           validateOnBlur
                                           validate={value => {
                                               return !value || value.length < 5
                                                   ? 'Field must be at least five characters'
                                                   : undefined;
                                           }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.field}>
                        <TextFieldInformed field='know'
                                           label='Sabias que'
                                           multiline={true}
                                           rows={3}
                                           validateOnChange
                                           validateOnBlur
                                           validate={value => {
                                               return !value || value.length < 5
                                                   ? 'Field must be at least five characters'
                                                   : undefined;
                                           }}
                        />
                    </Grid>

                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h4" component="h4">
                                Localización
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.field}>
                            <TextFieldInformed field='location[0]'
                                               label='Latitud'
                                               step={0.000001}
                                               type={'number'}
                                               min={-90}
                                               max={90}
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {

                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                                               inputProps={{
                                                   step: 0.000001,
                                                   type: 'number',
                                                   min: -90,
                                                   max: 90,
                                               }}
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.field}>
                            <TextFieldInformed field='location[1]'
                                               label='Longitud'
                                               step={0.000001}
                                               type={'number'}
                                               min={-180}
                                               max={180}
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {
                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                                               inputProps={{
                                                   step: 0.000001,
                                                   type: 'number',
                                                   min: -180,
                                                   max: 180,
                                               }}
                            />
                        </Grid>
                    </Grid>

                    <Scope scope="transport">
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h4" component="h4">
                                Transporte
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextFieldInformed field='own'
                                               label='En Auto'
                                               multiline={true}
                                               rows={3}
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {
                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextFieldInformed field='public'
                                               label='En Bus'
                                               multiline={true}
                                               rows={3}
                                               validateOnChange
                                               validateOnBlur
                                               validate={value => {
                                                   return !value || value.length < 5
                                                       ? 'Field must be at least five characters'
                                                       : undefined;
                                               }}
                            />
                        </Grid>
                    </Scope>

                    <Grid item xs={12} className={classes.field}>
                        <ArrayField field="activities">
                            {({add, fields}) => (
                                <Grid container spacing={8} alignItems="center"
                                      className={classes.field}>
                                    <Grid item xs={9}>
                                        <Typography color="inherit" variant="h5">
                                            Actividades
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button onClick={add} type="button" variant="contained"
                                                color="primary">
                                            Agregar Actividad
                                        </Button>
                                    </Grid>
                                    {fields.map(({field, key, remove}, i) => (
                                        <Grid container item xs={12} key={key}>
                                            <Grid item xs={9}>
                                                <TextFieldInformed
                                                    field={field}
                                                    label='Actividad'
                                                    validateOnChange
                                                    validateOnBlur
                                                    validate={value => {
                                                        return !value || value.length < 5
                                                            ? 'Field must be at least five characters'
                                                            : undefined;
                                                    }}/>
                                            </Grid>
                                            <Grid container item xs={3} alignItems={'center'}
                                                  justify={'center'}
                                                  className={classes.flex}>
                                                <IconButton type="button" onClick={remove}
                                                            color="primary">
                                                    <RemoveIcon color="inherit"/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </ArrayField>
                    </Grid>


                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">Guardar</Button>
                    </Grid>


                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <label>Values:</label>
                                <code>
                                    {JSON.stringify(formState.values)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Touched:</label>
                                <code>
                                    {JSON.stringify(formState.touched)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Errors:</label>
                                <code>
                                    {JSON.stringify(formState.errors)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Invalid:</label>
                                <code>
                                    {JSON.stringify(formState.invalid)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Pristine:</label>
                                <code>
                                    {JSON.stringify(formState.pristine)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Dirty:</label>
                                <code>
                                    {JSON.stringify(formState.dirty)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Submits:</label>
                                <code>
                                    {JSON.stringify(formState.submits)}
                                </code>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Error:</label>
                                <code>
                                    {JSON.stringify(formState.error)}
                                </code>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Form>
    );
}

export default PlaceForm
