import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {Field, Form, FormSpy} from 'react-final-form';
import {withStyles} from '@material-ui/core/styles';
import Link from "@material-ui/core/Link/Link";
import Grid from '@material-ui/core/Grid';

import Typography from '../../components/public/Typography';

import AppAppBar from "../../views/AppAppBar";
import AppForm from "../../views/AppForm";
import {withFirebase} from '../../decorators/firebase';
import * as ROUTES from '../../constants/routes';
import {email, required} from '../../forms/validation';
import AppFooter from "../../views/AppFooter";
import RFTextField from '../../forms/RFTextField';
import FormButton from '../../forms/FormButton';
import FormFeedback from '../../forms/FormFeedback';
import * as ROLES from "../../constants/roles";

const INITIAL_STATE = {
	data : {
		firstName: '',
		lastName : '',
		// username   : '',
		email    : '',
		password : '',
	},
	error: null,
};

const styles = theme => ({
	form    : {
		marginTop: theme.spacing(6),
	},
	button  : {
		marginTop   : theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	feedback: {
		marginTop: theme.spacing(2),
	},
});

@withRouter
@withStyles(styles)
@withFirebase
class SignUp extends Component {

	static propTypes = {
		classes: PropTypes.object.isRequired,
	};

	state = {
		...INITIAL_STATE,
		sent: false,
	};

	validate = values => {
		const errors = required(['firstName', 'lastName', 'email', 'password'], values, this.props);

		if (!errors.email) {
			const emailError = email(values.email, values, this.props);
			if (emailError) {
				errors.email = email(values.email, values, this.props);
			}
		}

		return errors;
	};

	handleSubmit = values => {
		const {firstName, lastName, email, password} = values;

		this.props.firebase
			.doCreateUserWithEmailAndPassword(email, password)
			.then(authUser => {
				// Create a user in your Firebase realtime database
				authUser.updateProfile({
					displayName: `${firstName} ${lastName}`
				});
				return this.props.firebase
					.user(authUser.user.uid)
					.set({
						firstName,
						lastName,
						email,
						roles: {
							[ROLES.USER]: true
						}
					});
			})
			.then(authUser => {
				this.setState({...INITIAL_STATE});
				this.props.history.push(ROUTES.HOME);
			})
			.catch(error => {
				this.setState({error});
			});
	};

	render() {
		const {classes} = this.props;
		const {data, sent} = this.state;

		return (
			<React.Fragment>
				<AppAppBar/>
				<AppForm>
					<React.Fragment>
						<Typography variant="h3" gutterBottom marked="center" align="center">
							Sign Up
						</Typography>
						<Typography variant="body2" align="center">
							<Link underline="always"
								  component={props => (<RouterLink to={ROUTES.SIGN_IN} {...props}/>)}>
								Already have an account?
							</Link>
						</Typography>
					</React.Fragment>
					<Form
						onSubmit={this.handleSubmit}
						initialValues={data}
						subscription={{submitting: true}}
						validate={this.validate}>
						{({handleSubmit, submitting, pristine, invalid}) => (
							<form onSubmit={handleSubmit} className={classes.form} noValidate>
								<Grid container spacing={8}>
									<Grid item xs={12} sm={6}>
										<Field
											autoFocus
											component={RFTextField}
											autoComplete="fname"
											fullWidth
											label="First name"
											name="firstName"
											required/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={RFTextField}
											autoComplete="lname"
											fullWidth
											label="Last name"
											name="lastName"
											required/>
									</Grid>
								</Grid>
								<Field
									autoComplete="email"
									component={RFTextField}
									disabled={submitting || sent}
									fullWidth
									label="Email"
									margin="normal"
									name="email"
									required/>
								<Field
									fullWidth
									component={RFTextField}
									disabled={submitting || sent}
									required
									name="password"
									autoComplete="current-password"
									label="Password"
									type="password"
									margin="normal"/>
								<FormSpy subscription={{submitError: true}}>
									{({submitError}) =>
										submitError ? (
											<FormFeedback className={classes.feedback} error>
												{submitError}
											</FormFeedback>
										) : null
									}
								</FormSpy>
								<FormButton
									className={classes.button}
									disabled={submitting || sent || pristine || invalid}
									color="secondary"
									fullWidth>
									{submitting || sent ? 'In progress…' : 'Sign Up'}
								</FormButton>
							</form>
						)}
					</Form>
				</AppForm>
				<AppFooter/>
			</React.Fragment>
		);
	}
}

const SignUpLink = () => (
	<Typography align="right">
		Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
	</Typography>
);

export default SignUp;

export {SignUpLink};

