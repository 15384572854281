import React from 'react';
import {Link} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import * as ROUTES from "../../../constants/routes";

const styles = theme => ({
	block: {
		display: 'block',
	},
});

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const List = ({classes, places}) => {
	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Address</TableCell>
					<TableCell>Status</TableCell>
					<TableCell>Actions</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{places.map(place => {
					return (
						<TableRow key={place.uid}>
							<TableCell component="th" scope="row">{place.name}</TableCell>
							<TableCell>{place.address}</TableCell>
							<TableCell>{place.status}</TableCell>
							<TableCell>
								<IconButton
									component={AdapterLink}
									to={`${ROUTES.PLACES}/edit/${place.uid}`}>
									<EditIcon className={classes.block} color="inherit"/>
								</IconButton>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>

		</Table>
	);
};

export default withStyles(styles)(List);
