import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {LayoutBody, Typography} from '../../../common';

const styles = theme => ({
	root      : {
		display        : 'flex',
		backgroundColor: theme.palette.secondary.light,
		overflow       : 'hidden',
	},
	layoutBody: {
		marginTop    : theme.spacing(10),
		marginBottom : theme.spacing(15),
		position     : 'relative',
		display      : 'flex',
		flexDirection: 'column',
		alignItems   : 'center',
	},
	item      : {
		display      : 'flex',
		flexDirection: 'column',
		alignItems   : 'center',
		padding      : `0px ${theme.spacing(5)}px`,
	},
	title     : {
		marginBottom: theme.spacing(14),
	},
	number    : {
		fontSize  : 24,
		fontFamily: theme.typography.fontFamily,
		color     : theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	image     : {
		height      : 55,
		marginTop   : theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	curvyLines: {
		pointerEvents: 'none',
		position     : 'absolute',
		top          : -180,
		opacity      : 0.7,
	},
	button    : {
		marginTop: theme.spacing(8),
	},
});

function WhatIsTakComponent(props) {
	const {classes} = props;

	return (
		<section className={classes.root}>
			<LayoutBody className={classes.layoutBody} width="large">
				<img
					src="/images/productCurvyLines.png"
					className={classes.curvyLines}
					alt="curvy lines"
				/>
				<Typography variant="h4" marked="center" className={classes.title} component="h2">
					¿Qué es Tak?
				</Typography>

				<div>
					<Typography variant="h5" align="center">
						Tak es una aplicación móvil y web, para conectar, descubrir y explorar Panamá.
					</Typography>
				</div>
				<div>
					<Grid container spacing={8}>
						<Grid item xs={12} md={4}>
							<div className={classes.item}>
								<img
									src="/images/productHowItWorks1.svg"
									alt="suitcase"
									className={classes.image}
								/>

								<Typography variant="h5" align="center">
									Aumentar el turismo, especialmente el interno; proporcionandole al
									usuario diversos lugares a visitar o actividades para realizar.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={classes.item}>
								<img
									src="/images/productHowItWorks2.svg"
									alt="graph"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Permitir a las personas ofrecer actividades, intercambiando su conocimiento por una
									experiencia al usuario lo cual será retribuido de forma monetaria.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={classes.item}>
								<img
									src="/images/productHowItWorks3.svg"
									alt="clock"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Facilitar a los usuarios la búsqueda de lugares turísticos o de interés en Panamá.
									Para compartir con familia o amigos nuevas experiencias.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</div>
			</LayoutBody>
		</section>
	);
}

WhatIsTakComponent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export const WhatIsTak = withStyles(styles)(WhatIsTakComponent);
export default WhatIsTak;
