import React from 'react';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({});

const List = ({classes, suggestedPlaces}) => {
	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Status</TableCell>
					<TableCell>Created At</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{suggestedPlaces.map(place => (
					<TableRow key={place.uid}>
						<TableCell>{place.name}</TableCell>
						<TableCell>{place.status}</TableCell>
						<TableCell>{moment.unix(place.created_at.seconds).format('DD/MM/YYYY hh:mm a')}</TableCell>
					</TableRow>
				))}
			</TableBody>

		</Table>
	);
};

export default withStyles(styles)(List);
