import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StarRatings from 'react-star-ratings';

const useStyles = makeStyles((theme) => createStyles({
    ratingValue: {
        backgroundColor: '#FFAB00',
        margin: '0 5px 0 0',
        color: 'white',
        fontWeight: 'bold',
        padding: '2px 3px',
        borderRadius: '3px',
    },

}));


export const PlaceRating = ({place}) => {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="body2" color="textSecondary" component="span"
                        className={classes.ratingValue}>
                {(4.3)}
            </Typography>
            <StarRatings rating={4.3}
                         starRatedColor="#FFAB00"
                         numberOfStars={5}
                         starDimension="16px"
                         starSpacing="0px"
                         name='rating'/>
            <Typography variant="body2" color="textSecondary" component="span">
                {`(${21})`}
            </Typography>
        </div>
    );
};


