import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

const Caption = ({children, container, effect, duration, delay, ease, offSetX, offSetY, origin, position}) => (
	<div
		className={classNames("ms-layer", container)}
		data-effect={effect}
		data-duration={duration}
		data-delay={delay}
		data-ease={ease}
		data-offset-x={offSetX}
		data-offset-y={offSetY}
		data-origin={origin}
		data-position={position}>{children}</div>
);

Caption.propTypes = {
	container: PropTypes.oneOf(['msp-cn-1-1', 'msp-cn-1-2', 'msp-cn-1-3', 'msp-cn-1-4', 'msp-cn-1-5']),
	effect   : PropTypes.string,
	duration : PropTypes.number.isRequired,
	delay    : PropTypes.number.isRequired,
	ease     : PropTypes.oneOf(['linear', 'ease', ...['', 'Cubic', 'Circ', 'Expo', 'Quad', 'Quart', 'Quint', 'Sine', 'Back'].reduce((o, f) => ([...o, ...['In', 'Out', 'InOut'].map(i => `ease${i}${f}`)]), [])]),
	offSetX  : PropTypes.number.isRequired,
	offSetY  : PropTypes.number.isRequired,
	origin   : PropTypes.oneOf(['t', 'm', 'b'].reduce((o, v) => ([...o, ...['l', 'c', 'r'].map(h => `${v}${h}`)]), [])).isRequired,
	position : PropTypes.oneOf(['normal', 'static', 'fixed'])
};

Caption.defaultProps = {
	container: 'msp-cn-1-5',
	effect   : 't(true,n,n,-500,n,n,n,n,n,n,n,n,n,n,n)',
	ease     : 'easeOutQuint',
	position : 'normal'
};

export default Caption;
