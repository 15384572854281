import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink, withRouter,} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import {Field, Form, FormSpy} from 'react-final-form';
import Typography from '../../components/public/Typography';
import AppFooter from '../../views/AppFooter';
import AppAppBar from '../../views/AppAppBar';
import AppForm from '../../views/AppForm';
import {email, required} from '../../forms/validation';
import RFTextField from '../../forms/RFTextField';
import FormButton from '../../forms/FormButton';
import FormFeedback from '../../forms/FormFeedback';

import {SignUpLink} from '../SignUp';
import {PasswordForgetLink} from '../PasswordForget';
import {withFirebase} from '../../decorators/firebase';
import * as ROUTES from '../../constants/routes';


// https://github.com/the-road-to-react-with-firebase/react-firebase-authentication/blob/deploy-security-rules/src/components/SignIn/index.js
const INITIAL_STATE = {
	data : {
		email   : '',
		password: ''
	},
	error: null
};

const styles = theme => ({
	form    : {
		marginTop: theme.spacing(6),
	},
	button  : {
		marginTop   : theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	feedback: {
		marginTop: theme.spacing(2),
	},
});

@withRouter
@withStyles(styles)
@withFirebase
class SignIn extends React.Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
	};

	state = {
		...INITIAL_STATE,
		sent: false,
	};

	validate = values => {
		const errors = required(['email', 'password'], values, this.props);

		if (!errors.email) {
			const emailError = email(values.email, values, this.props);
			if (emailError) {
				errors.email = email(values.email, values, this.props);
			}
		}

		return errors;
	};

	handleSubmit = (values) => {
		const {email, password} = values;
		this.props.firebase
			.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				this.setState({...INITIAL_STATE});
				this.props.history.push(ROUTES.HOME);
			})
			.catch(error => {
				this.setState({error});
			});
	};

	render() {
		const {classes} = this.props;
		const {sent, data} = this.state;

		return (
			<React.Fragment>
				<AppAppBar/>
				<AppForm>
					<React.Fragment>
						<Typography variant="h3" gutterBottom marked="center" align="center">
							Sign In
						</Typography>
						<Typography variant="body2" align="center">
							{'Not a member yet? '}
							<Link align="center"
								  underline="always"
								  component={props => (<RouterLink to={ROUTES.SIGN_UP} {...props}/>)}>
								Sign Up here
							</Link>
						</Typography>
					</React.Fragment>
					<Form
						onSubmit={this.handleSubmit}
						initialValues={data}
						subscription={{submitting: true}}
						validate={this.validate}>
						{({handleSubmit, submitting, pristine, invalid}) => (
							<form onSubmit={handleSubmit} className={classes.form} noValidate>
								<Field
									autoComplete="email"
									autoFocus
									component={RFTextField}
									disabled={submitting || sent}
									fullWidth
									label="Email"
									margin="normal"
									name="email"
									required
									size="large"
								/>
								<Field
									fullWidth
									size="large"
									component={RFTextField}
									disabled={submitting || sent}
									required
									name="password"
									autoComplete="current-password"
									label="Password"
									type="password"
									margin="normal"
								/>
								<FormSpy subscription={{submitError: true}}>
									{({submitError}) =>
										submitError ? (
											<FormFeedback className={classes.feedback} error>
												{submitError}
											</FormFeedback>
										) : null
									}
								</FormSpy>
								<FormButton
									className={classes.button}
									disabled={submitting || sent || pristine || invalid}
									size="large"
									color="secondary"
									fullWidth>
									{submitting || sent ? 'In progress…' : 'Sign In'}
								</FormButton>

								<SignUpLink/>
							</form>
						)}
					</Form>
					<Typography align="center">
						<PasswordForgetLink/>
					</Typography>
				</AppForm>
				<AppFooter/>
			</React.Fragment>
		);
	}
}

export default SignIn
