import React, {useState} from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import {CSSTransition} from 'react-transition-group';

import {DotsLoader} from '../../components/common';
import {PlaceItem2} from '../../components/places';

import {PlacesMap} from './Map';

const startTransitionStyles = {
    bottom: 0,
    display: 'none',
    left: 0,
    position: 'absolute',
    right: 0,
    top: -5,
    width: '100%',
};

const finishTransitionStyles = {
    top: 0,
    width: '100%',
};

const useStyles = makeStyles((theme) => createStyles({
    root: {
        '&::before': {
            content: " ",
            display: 'table',
        }
    },
    container: {
        position: 'relative',
    },
    right: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {},
        [theme.breakpoints.up('md')]: {
            width: 840,
        },
        [theme.breakpoints.up('lg')]: {
            width: 840,
        },
    },
    header: {
        paddingLeft: 24,
        paddingRight: 24,
        maxWidth: 'none',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginRight: 'auto',
            position: 'relative',
        },
    },
    headerTitle: {
        paddingTop: 32,
        [theme.breakpoints.up('md')]: {
            paddingTop: 50,
        },

        '& h1': {
            fontSize: '2em',
        }
    },
    scroll: {
        display: 'flex',
        padding: `${theme.spacing() * 3}px`,
        overflow: 'hidden',
    },
    left: {
        bottom: 0,
        display: 'none',
        left: 0,
        position: 'absolute',
        right: 0,
        top: -5,
        [theme.breakpoints.down('sm')]: {},
        [theme.breakpoints.up('md')]: {},
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            left: 'auto',
            width: 'calc(100% - 840px)',
        },
        '@supports (position: sticky) or (position: -webkit-sticky)': {
            top: 0,
        },
    },
    aside: {
        height: '100vh',
        width: '100%',
        paddingTop: 70,
        marginTop: -80,
        position: 'sticky',
        top: 0,
    },
    map: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    mapTransitionEnter: {
        ...startTransitionStyles,
    },
    mapTransitionEnterActive: {
        ...finishTransitionStyles,
        transition: 'all 400ms',
    },
    mapTransitionExit: {
        ...finishTransitionStyles,
    },
    mapTransitionExitActive: {
        ...startTransitionStyles,
        transition: 'all 400ms',
    },
}));


function PlacesComponent({places, center}) {
    const [isMapVisible, setMapVisible] = useState(true);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={[isMapVisible && classes.right]}>
                    <div className={classes.header}>
                        <div className={classes.headerTitle}>
                            <section>
                                <Typography variant="subtitle1">
                                    [n] lugares
                                </Typography>

                                <Typography variant="h1">
                                    Lugares en Panamá
                                </Typography>
                                <button onClick={() => setMapVisible(p => !p)}>Mapa</button>
                            </section>
                        </div>
                    </div>
                    <InfiniteScroll
                        element={Grid}
                        className={classes.scroll}
                        pageStart={0}
                        container
                        spacing={2}
                        loadMore={() => null}
                        hasMore={false}
                        loader={<DotsLoader key={0}/>}
                        useWindow={true}>
                        {places.map((place, index) => (<PlaceItem2 key={index} place={place}/>))}
                    </InfiniteScroll>
                </div>
                <CSSTransition
                    in={isMapVisible}
                    timeout={400}
                    unmountOnExit
                    classNames={{
                        enter: classes.mapTransitionEnter,
                        enterActive: classes.mapTransitionEnterActive,
                        exit: classes.mapTransitionExit,
                        exitActive: classes.mapTransitionExitActive,
                    }}>
                    <div className={classes.left}>
                        <aside className={classes.aside}>
                            <PlacesMap className={classes.map} center={center} places={places}/>
                        </aside>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
}

export default PlacesComponent;


