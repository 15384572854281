import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import {withFirebase} from '../../decorators/firebase';
import PlaceContainer from './components/container';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";

const styles = theme => {
	// console.log(theme);
	return {
		mainContent  : {
			flex           : 1,
			maxWidth       : 936,
			margin         : 'auto',
			overflow       : 'hidden',
			padding        : '48px 36px 0',
			backgroundColor: 'transparent',
		},
		card__header : {
			backgroundColor: theme.palette.primary.main,
			padding        : '16px 0',
			position       : 'fixed',
		},
		secondary_bar: {
			zIndex: 0,
		},
	};
};

@withStyles(styles)
@withFirebase
class PlacesAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading    : false,
			places     : [],
			anchorEl   : null,
			selectedTab: 0
		};
	}

	render() {
		const {classes} = this.props;
		const {loading} = this.state;

		return (
			<PlaceContainer>
				<main className={classes.mainContent}>
					{loading && <div>Loading ...</div>}


					<Card className={classes.card}>
						<CardHeader className={classes.card__header} disableTypography
									title={<AppBar
										component="div"
										className={classes.secondary_bar}
										color="primary"
										position="static"
										elevation={0}>
										<Toolbar>
											<Grid container spacing={8} alignItems="center">
												<Grid item xs>
													<Typography color="inherit" variant="h5">
														New Place
													</Typography>
												</Grid>
												<Grid item/>
											</Grid>
										</Toolbar>
									</AppBar>}/>

						<CardContent>
							<Typography gutterBottom variant="h5" component="h2">
								Lizard
							</Typography>
							<Typography variant="body2" color="textSecondary" component="p">
								Lizards are a widespread group of squamate reptiles, with over 6,000 species,
								ranging
								across all continents except Antarctica
							</Typography>
						</CardContent>
					</Card>

				</main>
			</PlaceContainer>
		);
	}
}

export default PlacesAdd;
