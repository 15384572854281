import React from 'react';
import {Route, Switch} from "react-router-dom";

import ROUTES from "../../constants/routes";
import SignUpPage from "../../pages/SignUp";
import SignInPage from "../../pages/SignIn";
import PasswordForgetPage from "../../pages/PasswordForget";
import Layout from "./Layout";

export default () => (
	<Layout>
		<Switch>
			<Route exact path={ROUTES.AUTH} component={SignInPage}/>
			<Route path={ROUTES.SIGN_UP} component={SignUpPage}/>
			<Route path={ROUTES.SIGN_IN} component={SignInPage}/>
			<Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
		</Switch>
	</Layout>
);
