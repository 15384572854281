import React from 'react';
import {Switch} from "react-router-dom";

import ROUTES from "../../constants/routes";
import AdminRoute from "../../components/shared/AdminRoute";

import AccountPage from "../../pages/Account";
import AdminPage from "../../pages/Admin";
import PlacesPage from '../../pages/Places';
import SuggestedPlacesPage from '../../pages/SuggestedPlaces';

const Content = () => (
	<Switch>
		<AdminRoute exact path={ROUTES.PRIVATE} component={AccountPage}/>
		<AdminRoute exact path={ROUTES.ACCOUNT} component={AccountPage}/>
		<AdminRoute exact path={ROUTES.ADMIN} component={AdminPage}/>
		<AdminRoute path={ROUTES.PLACES} component={PlacesPage}/>
		<AdminRoute path={ROUTES.SUGGESTED_PLACES} component={SuggestedPlacesPage}/>
	</Switch>
);

export default Content;
