import React from 'react';

import {Home} from './home.component';

const ContainerComponent = () => (
	<Home/>
);

export const Container = ContainerComponent;

export default Container;

