import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {OverlayView} from '@react-google-maps/api';

import {point2LatLng} from '../../../../utils/geo';
import {PlaceCarousel} from '../../../../atoms/PlaceCarousel';
import {PlaceRating} from '../../../../atoms/PlaceRating';

const useStyles = makeStyles((theme) => createStyles({
    placeItemOverlay: {
        pointerEvents: 'none',
        width: 274,
        marginBottom: 0
    },
    placeItemOverlayContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.28) 0px 8px 28px',
        color: 'rgb(34, 34, 34)',
        pointerEvents: 'auto',
        position: 'relative',
        width: 274,
    },
    placeItemOverlayLink: {
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    placeItemOverlayImages: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        position: 'relative',
        '-webkit-mask-image': '-webkit-radial-gradient(center, white, black)',
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        [theme.breakpoints.down('sm')]: {},
        [theme.breakpoints.up('md')]: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
    },
    placeItemOverlayImagesContainer: {
        position: 'relative',
    },
    placeItemOverlayImagesContent: {
        position: 'relative',
        width: '100%',
        zIndex: 0,
        paddingTop: '66.6667%',
        backgroundColor: 'rgb(216, 216, 216)',
        backgroundPosition: 'initial initial',
        backgroundRepeat: 'initial initial',
    },
    placeItemOverlayImagesSlider: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
    },
    placeItemOverlayImagesSliderContent: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    placeItemOverlayImagesSliderLink: {
        display: 'block',
        left: 0,
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: 1,
    },
    placeItemOverlayInfo: {
        padding: 16,
    },

}));


export const PlaceOverlayView = ({visible, place}) => {
    const classes = useStyles();

    if (!visible) {
        return null;
    }
    return (
        <OverlayView
            position={point2LatLng(place.location)}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height / 2),
            })}>
            <div className={classes.placeItemOverlay}>
                <div className={classes.placeItemOverlayContainer}>
                    <a href={`/places/${place.uid}`} className={classes.placeItemOverlayLink}/>
                    <div className={classes.placeItemOverlayImages}>
                        <div className={classes.placeItemOverlayImagesContainer}>
                            <div className={classes.placeItemOverlayImagesContent}>
                                <div className={classes.placeItemOverlayImagesSlider}>
                                    <span>
                                        <div className={classes.placeItemOverlayImagesSliderContent}>
                                    <a href={`/places/${place.uid}`} className={classes.placeItemOverlayImagesSliderLink}/>
                                    <PlaceCarousel place={place}/>
                                    </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.placeItemOverlayInfo}>

                        <PlaceRating place={place}/>

                        <Typography variant="subtitle1" noWrap>
                            {place.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {place.address}
                        </Typography>
                    </div>
                </div>
            </div>
        </OverlayView>
    );
};


