import React from 'react';
import {compose} from 'recompose';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/public/AppBar';
import Toolbar, {styles as toolbarStyles} from '../components/public/Toolbar';

import * as ROUTES from '../constants/routes';
import AppHeaderAuth from "./AppHeaderAuth";

const styles = theme => ({
	appTitle      : {
		textDecoration: 'none',
		color         : 'inherit',
	},
	title         : {
		fontSize: 24,
	},
	placeholder   : toolbarStyles(theme).root,
	toolbar       : {
		justifyContent: 'space-between',
	},
	left          : {
		flex: 1,
	},
	leftLinkActive: {
		color: theme.palette.common.white,
	}
});

const AppAppBar = ({classes}) => (
	<div>
		<AppBar position="fixed">
			<Toolbar className={classes.toolbar}>
				<div className={classes.left}>
					<Link
						variant="h6"
						underline="none"
						color="inherit"
						className={classes.title}
						component={(props) => (<RouterLink to={ROUTES.LANDING} {...props}/>)}>
						{'takapp'}
					</Link>
				</div>
				<AppHeaderAuth/>
			</Toolbar>
		</AppBar>
		<div className={classes.placeholder}/>
	</div>
);

AppAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withRouter)(AppAppBar);
