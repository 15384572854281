import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';

import AuthUserContext from "../../decorators/auth/context";
import * as ROUTES from "../../constants/routes";
import NavigatiorProfileMenu from "../../components/admin/NavigatorProfileMenu";

const categories = [
	{
		id      : 'Develop',
		children: [
			{id: 'Usuarios', icon: <PeopleIcon/>, active: false, auth: true, component: Link, to: ROUTES.ADMIN},
			{id: 'Database', icon: <DnsRoundedIcon/>, auth: true, component: Link, to: ROUTES.HOME},
			{id: 'Places', icon: <PermMediaOutlinedIcon/>, auth: true, component: Link, to: ROUTES.PLACES},
			{id: 'Suggested Places', icon: <PermMediaOutlinedIcon/>, auth: true, component: Link, to: ROUTES.SUGGESTED_PLACES},
			{id: 'Hosting', icon: <PublicIcon/>, auth: true, component: Link, to: ROUTES.ADMIN},
			{id: 'Sign In', icon: <SettingsEthernetIcon/>, component: Link, to: ROUTES.SIGN_IN},
			{id: 'ML Kit', icon: <SettingsInputComponentIcon/>},
		],
	}
];

const styles = theme => ({
	categoryHeader       : {
		paddingTop   : 16,
		paddingBottom: 16,
	},
	categoryHeaderPrimary: {
		color: theme.palette.common.white,
	},
	item                 : {
		paddingTop   : 4,
		paddingBottom: 4,
		color        : 'rgba(255, 255, 255, 0.7)',
	},
	itemCategory         : {
		backgroundColor: '#232f3e',
		boxShadow      : '0 -1px 0 #404854 inset',
		paddingTop     : 16,
		paddingBottom  : 16,
	},
	itemProfile          : {
		padding: '10px 15px',
	},
	firebase             : {
		fontSize  : 24,
		fontFamily: theme.typography.fontFamily,
		color     : theme.palette.common.white,
	},
	itemActionable       : {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	itemActiveItem       : {
		color: '#4fc3f7',
	},
	itemPrimary          : {
		color        : 'inherit',
		fontSize     : theme.typography.fontSize,
		'&$textDense': {
			fontSize: theme.typography.fontSize,
		},
	},
	textDense            : {},
	divider              : {
		marginTop: theme.spacing(2),
	},
});

@withStyles(styles)
class Navigation extends React.Component {


	render() {
		const {authUser, classes, ...other} = this.props;
		return (
			<Drawer variant="permanent" {...other}>
				<List disablePadding>
					<ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
						[TAK]
					</ListItem>
					{Boolean(authUser) && (
						<NavigatiorProfileMenu authUser={authUser}/>
					)}
					<ListItem className={classNames(classes.item, classes.itemCategory)}>
						<ListItemIcon>
							<HomeIcon/>
						</ListItemIcon>
						<ListItemText
							classes={{
								primary: classes.itemPrimary,
							}}>
							Administration
						</ListItemText>
					</ListItem>
					{categories.map(({id, children}) => (
						<React.Fragment key={id}>
							<ListItem className={classes.categoryHeader}>
								<ListItemText
									classes={{
										primary: classes.categoryHeaderPrimary,
									}}>
									{id}
								</ListItemText>
							</ListItem>
							{children
								.filter(item => Boolean(authUser) ? true : Boolean(item.auth) === false)
								.map(({id: childId, icon, active, auth, ...prop}) => (
									<ListItem
										{...prop}
										button
										dense
										key={childId}
										className={classNames(
											classes.item,
											classes.itemActionable,
											active && classes.itemActiveItem,
										)}>
										<ListItemIcon>{icon}</ListItemIcon>
										<ListItemText
											classes={{
												primary  : classes.itemPrimary,
												// textDense: classes.textDense,
											}}>
											{childId}
										</ListItemText>
									</ListItem>
								))}
							<Divider className={classes.divider}/>
						</React.Fragment>
					))}
				</List>
			</Drawer>)
	}
}

const Navigator = ({...props}) => (
	<AuthUserContext.Consumer>
		{authUser => <Navigation {...props} authUser={authUser}/>}
	</AuthUserContext.Consumer>
);

Navigator.propTypes = {
	classes: PropTypes.object
};

export default Navigator;
