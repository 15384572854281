import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import debug from "debug";

const config = {
    apiKey           : process.env.REACT_APP_API_KEY,
    authDomain       : process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL      : process.env.REACT_APP_DATABASE_URL,
    projectId        : process.env.REACT_APP_PROJECT_ID,
    storageBucket    : process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};


const log = debug("app:db");

firebase.initializeApp(config);

export const db = firebase.firestore();

db.enablePersistence().catch(function(err) {
    log("failed to enablePersistence: %s", err);
    console.error(err);
});
