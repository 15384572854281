export function getGreetingTime() {
    let currentTime: Date = new Date();
    const currentHour = currentTime.getHours();
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return 'Buenas tardes';
    } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return 'Buenas noches';
    }
    // Between dawn and noon
    return 'Buenos días';
}


export function isNigth() {
    let currentTime: Date = new Date();
    const currentHour = currentTime.getHours();
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return false;
    } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return true;
    }
    // Between dawn and noon
    return false;
}
